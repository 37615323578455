import React, {useEffect, useState} from "react";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import RevitComponent from "./ArchitectComponents/RevitComponent";
import './ArhitectStyles/ArhitectPage.css';
import QuestionAnswers from "./ArchitectComponents/QuestionAnswers";
import CreateArchitectProject from "./ArchitectComponents/CreateArchitectProject/CreateArchitectProject";
import {IconRevitInfo} from "../../IconComponents/IconComponents";
import {useLocation, useParams} from "react-router-dom";

import {toast} from "react-toastify";
import {Fragment, ItemType, FragmentData} from "../../types/TemplateType";
import {AddressData, FileData} from "../Step1BOMCreate/CreateProjectBom/CreateProjectBom";
import {
    addCustomFragment,
    addExistFragment,
    createArchitectProject,
    fetchItemTypes, getAllSkipsRevitObject,
    getCreatedProjectByUrn,
    getProjectByUrn,
    getRevitFragment, removeCustomFragment, removeExistFragment, skipRevitFragment,
    updateProject
} from "./architectRequests";
import ItemTypesSelected from "./ArchitectComponents/ItemTypesSelected/ItemTypesSelected";
import ItemTypeCustomFragmentSelectModal from "./ArchitectComponents/Modals/ItemTypeCustomFragmentSelectModal";
import SelectedFragmentsComponent from "./ArchitectComponents/SelectedFragmentsComponent";
import {ArchitectProject} from "../../types/ArhitectProject";
import DeleteModal from "../../components/Modals/ModalDelete/ModalDelete";
import CheckingSkip from "./ArchitectComponents/Modals/CheckingSkip";

export interface AllFragments {
    currentIndex: number;
    id: number;
    inProjectFragmentId: number;
    name: string;
    custom: boolean;
    itemTypes: ItemType[];
    data?: FragmentData[];
    revit_object_id: number | null;
}

const ArhitectPage = () => {
    const {urn} = useParams<{ urn: string }>();
    const [allFragments, setAllFragments] = useState<AllFragments[]>([]);

    const [isSavedProject, setIsSavedProject] = useState<boolean>(true);

    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [showWizard, setShowWizard] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [totalObjects, setTotalObjects] = useState<number>(0);
    const [externalIdToDbId, setExternalIdToDbId] = useState<Record<string, number>>({});
    const [skipsObjectsRevitProject, setSkipsObjectsRevitProject] = useState<number[]>([])
    const [allSkipsObjects, setAllSkipsObjects] = useState([{
        externalId: ''
    }])
    const location = useLocation();
    const state = location.state as { urn: string; fileName: string };
    const {fileName} = state || {};
    const [skipOpenModal, setSkipOpenModal] = useState<boolean>(false);
    const [checkingSkip, setCheckingSkip] = useState<boolean>(false);

    const handleNextStep = () =>{
        if (checkingSkip){
            toast.warn("Deactivated the mode of viewing skipped objects")
            const lastCurrentIndex = localStorage.getItem('LastCurrentIndex');
            setCurrentIndex(Number(lastCurrentIndex))
            localStorage.removeItem('LastCurrentIndex');
            setCheckingSkip(false);
            return;
        }
        if (skipsObjectsRevitProject.length > 0){
            localStorage.setItem('LastCurrentIndex',String(currentIndex))
            setSkipOpenModal(true)
            return
        }
    }

    const skipOnConfirm = () =>{
        console.log('test')
    }

    const skipChkecking = () =>{
        setCheckingSkip(true)
        setSkipOpenModal(false)
        toast.success('You\'ve activated the skip view')
        const minimalIndex = Math.min(...skipsObjectsRevitProject)
        setCurrentIndex(minimalIndex)
    }


    const [projectData, setProjectData] = useState<AddressData>({
        address: {
            full_address: '',
            latitude: '',
            longitude: ''
        },
        target_date: '',
        title: ''
    });
    const [projectImg, setProjectImg] = useState<File[] | null>(null);

    const [externalIds, setExternalIds] = useState<string[]>([]);
    const [projectIdByUrn, setProjectIdByUrn] = useState<number | null>(null);
    const [fragments, setFragments] = useState<Fragment[]>([]);
    const [selecting, setSelecting] = useState<boolean>(false);
    const [currentFragment, setCurrentFragment] = useState<AllFragments | null>(null);

    const [arhitectProject, setArhitectProject] = useState<ArchitectProject | null>(null);
    const [skipFragmentData, setSkipFragmentData] = useState<{
        id: number | null;
        name: string | null,
        revit_object_id: number | null,
        measurements: { area: string | null, length: string | null, volume: string | null, width: string | null },
    }>({
        id: null,
        name: null,
        revit_object_id: null,
        measurements: {
            area: null,
            length: null,
            volume: null,
            width: null,
        }
    });

    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [removeCustomFragmentState, setRemoveCustomFragmentState] = useState<boolean>(false);
    const [removeExistFragmentState, setRemoveExistFragmentState] = useState<boolean>(false);

    const deleteCansel = () => {
        setIsDeleteOpen(false)
        if (removeExistFragmentState) {
            setSelecting(false)
        } else if (removeCustomFragmentState) {
            setSelecting(true)
        }
    }

    const [finalData, setFinalData] = useState<FragmentData[] | null>(null);
    const [selectedFragmentId, setSelectedFragmentId] = useState<number | null>(null);
    const [existDataFragment, setExistDataFragment] = useState<Fragment | null>(null)
    const [isUpdatedFragment, setIsUpdatedFragment] = useState<{ [key: number]: boolean }>({});

    const toggleFragmentSelection = (id: number, fragment: Fragment) => {
        if (selectedFragmentId === id) {
            setFinalData(null);
            setExistDataFragment(null)
            setSelectedFragmentId(null);
        } else if (currentFragment?.currentIndex !== currentIndex) {
            setExistDataFragment(fragment)
            setSelectedFragmentId(id);
        } else if (currentFragment?.currentIndex === currentIndex && currentFragment.id === id) {
            setExistDataFragment(fragment)
            setSelectedFragmentId(id);
        } else if (currentFragment?.currentIndex === currentIndex && currentFragment.id !== id) {
            setExistDataFragment(fragment)
            setSelectedFragmentId(id);
        }
    };


    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [sortDir, setSortDir] = useState<'asc' | 'desc'>('asc');
    const [itemTypes, setItemTypes] = useState<ItemType[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [selectedItemTypes, setSelectedItemTypes] = useState<ItemType[]>([]);
    const [itemTypeCount, setItemTypeCount] = useState<{ [key: number]: number }>({});
    const onClose = () => setIsOpen(false);

    const handleAddItemType = (item: ItemType) => {
        setSelectedItemTypes((prevSelected) => [...prevSelected, item]);
        setItemTypeCount((prevCount) => ({
            ...prevCount,
            [item.id]: (prevCount[item.id] || 0) + 1,
        }));
    };

    const handleRemoveFragmentFromProject = async () => {
        if (!arhitectProject) return;
        setIsDeleteOpen(false)
        const currentFragmentFromProject = arhitectProject?.data?.fragments.find((fragment) => fragment.current_index === currentIndex);
        if (!currentFragmentFromProject) return null
        const body = {
            id: currentFragmentFromProject.id,
            revit_object_id: currentFragmentFromProject.revit_object_id,
        }
        const response = await removeExistFragment(arhitectProject?.id, body)
        if (response !== null) {
            setAllFragments(prevFragments =>
                prevFragments.filter(fragment => fragment.currentIndex !== currentIndex)
            );
            setArhitectProject(response)
            setRemoveExistFragmentState(false)
            setIsOpen(true)
            setSelecting(true);
        } else return null
    };

    const handleRemoveCustomFragmentFromProject = async () => {
        if (!arhitectProject) return;
        setIsDeleteOpen(false)
        const currentFragmentFromProject = arhitectProject?.data?.customFragments.find((fragment) => fragment.data.current_index === currentIndex);
        if (!currentFragmentFromProject) return null
        const body = {
            customFragmentId: currentFragmentFromProject.id
        }
        const response = await removeCustomFragment(arhitectProject?.id, body)
        if (response !== null) {
            setAllFragments(prevFragments =>
                prevFragments.filter(fragment => fragment.currentIndex !== currentIndex)
            );
            setArhitectProject(response)
            setRemoveCustomFragmentState(false)
            setIsOpen(false)
            setSelectedItemTypes([])
            setSelecting(false);
        } else return null
    };

    const handleRemoveItemType = (itemType: ItemType) => {
        setItemTypeCount((prevCount) => {
            const currentCount = prevCount[itemType.id] || 0;

            if (currentCount > 1) {
                setSelectedItemTypes((prevSelected) => {
                    const index = prevSelected.findIndex((selected) => selected.id === itemType.id);
                    if (index !== -1) {
                        const updated = [...prevSelected];
                        updated.splice(index, 1);
                        return updated;
                    }
                    return prevSelected;
                });
            } else if (currentCount === 1) {
                setSelectedItemTypes((prevSelected) =>
                    prevSelected.filter((selected) => selected.id !== itemType.id)
                );
            }
            return {
                ...prevCount,
                [itemType.id]: Math.max(0, currentCount - 1),
            };
        });
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };
    useEffect(() => {
        const fetchProject = async () => {
            try {
                if (!urn) return;

                const createdProjectResponse = await getCreatedProjectByUrn(urn);
                if (createdProjectResponse.status !== 200 || !createdProjectResponse.data) return;

                setArhitectProject(createdProjectResponse.data);
                setShowWizard(true);

                const skipsObject = await getAllSkipsRevitObject(createdProjectResponse.data.revit_project_id);
                setAllSkipsObjects(skipsObject);

                const projectData = createdProjectResponse.data.data;

                const allProjectFragments = projectData?.fragments && Array.isArray(projectData.fragments)
                    ? projectData.fragments.map((fragment: Fragment) => ({
                        currentIndex: fragment.current_index ?? 0,
                        id: fragment.id ?? 0,
                        name: fragment.title ?? "Untitled",
                        revit_object_id: fragment.revit_object_id ?? 0,
                        custom: false,
                        data: Object.values(fragment.data || {})
                            .filter(item => typeof item === 'object' && item !== null && !Array.isArray(item))
                            .map(({ key, value, select, answers, question }) => ({
                                key: key ?? "",
                                value: value ?? "",
                                question: question ?? "",
                                select: select ?? "",
                                answers: answers?.map((answer: { title: any; value: any }) => ({
                                    title: answer.title ?? "",
                                    value: answer.value ?? "",
                                    selected: false
                                })) || [],
                                item_types: fragment.data?.item_types || []
                            })),
                        itemTypes: fragment.data?.item_types || []
                    }))
                    : [];

                const customFragments = projectData?.customFragments && Array.isArray(projectData.customFragments)
                    ? projectData.customFragments.map((customFragment: any) => ({
                        currentIndex: customFragment.data?.current_index ?? 0,
                        custom: true,
                        id: customFragment.id ?? 0,
                        itemTypes: customFragment.data?.item_types || [],
                        name: customFragment.title ?? "Untitled",
                        revit_object_id: customFragment.revit_object_id ?? 0,
                        inProjectFragmentId: customFragment.id ?? 0
                    }))
                    : [];

                const mergedFragments = [...allProjectFragments, ...customFragments];

                mergedFragments.sort((a, b) => a.currentIndex - b.currentIndex);

                if (projectData?.current_index !== undefined && projectData?.current_index !== null) {
                    setCurrentIndex(projectData.current_index);
                } else {
                    setCurrentIndex(0);
                }

                setAllFragments(mergedFragments);

                const projectId = await getProjectByUrn(urn);
                if (projectId) setProjectIdByUrn(projectId);
            } catch (error) {
                console.error('Error fetching project:', error);
                toast.error('Failed to retrieve the revit project. Please refresh the page (F5)');
            }
        };

        fetchProject();
    }, [urn]);



    useEffect(() => {
        if (checkingSkip || !arhitectProject || allSkipsObjects.length === 0 || Object.keys(externalIdToDbId).length === 0) return;

        const skippedDbIds = allSkipsObjects
            .map(obj => {
                const normalizedKey = String(obj.externalId).trim();
                return externalIdToDbId[normalizedKey];
            })
            .filter(dbId => dbId !== undefined);

        const skippedIndexes = skippedDbIds
            .map(dbId => externalIds.findIndex(externalId => externalIdToDbId[externalId] === dbId))
            .filter(index => index !== -1);

        setSkipsObjectsRevitProject(skippedIndexes);
    }, [checkingSkip, arhitectProject, allSkipsObjects, externalIdToDbId]);


    useEffect(() => {
        const fetchRevitFragment = async () => {
            try {
                if (projectIdByUrn && externalIds) {
                    const fragmentData = await getRevitFragment(String(externalIds[currentIndex]), projectIdByUrn);
                    setFragments(fragmentData.fragments);
                    setSkipFragmentData({
                        id: fragmentData.id,
                        name: fragmentData.name ?? null,
                        measurements: fragmentData.measurements ?? null,
                        revit_object_id: fragmentData.revit_object_id ?? null,
                    });
                }
            } catch (error) {
                toast.error('Failed to retrieve the revit project. Please refresh the page (F5)');
            }
        };
        if (externalIds[currentIndex]) fetchRevitFragment();
    }, [externalIds, currentIndex, projectIdByUrn]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!allFragments.length || !fragments.length || !currentFragment) return;
        const actualFragment = fragments.find((fragment) => fragment.id === currentFragment.id);
        if (actualFragment) {
            setExistDataFragment(actualFragment);
        }
    }, [currentIndex, allFragments, fragments]);

    useEffect(() => {
        if (allFragments) {
            const currentFragment = allFragments.find((fragment) => fragment.currentIndex === currentIndex);
            if (currentFragment) {
                setCurrentFragment(currentFragment);
            } else {
                setCurrentFragment(null)
            }
        }

    }, [arhitectProject, currentIndex, allFragments]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isLoading) return;

        if (currentFragment && currentFragment.currentIndex === currentIndex) {
            if (currentFragment.custom) {
                setSelecting(true);
                setSelectedItemTypes(currentFragment.itemTypes);

                const newItemTypeCount: { [key: number]: number } = {};
                currentFragment.itemTypes.forEach(item => {
                    newItemTypeCount[item.id] = (newItemTypeCount[item.id] || 0) + 1;
                });

                setItemTypeCount(newItemTypeCount);
            } else {
                setSelecting(false);
                setItemTypeCount({});
            }
        } else if (currentFragment !== null && fragments.length === 0 && !isLoading) {
            toast.info('There are no suitable fragments for the current revit object. You can only create a custom fragment');
            setSelecting(true);
            setItemTypeCount({});
            setSelectedItemTypes([]);
            setIsModalLoading(true);
            setIsOpen(true);
        } else if (!currentFragment) {
            setSelecting(false);
            setItemTypeCount({});
            setSelectedItemTypes([]);
        }
    }, [currentIndex, currentFragment, fragments, isLoading]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (fragments.length === 0) {
                setIsLoading(false);
            }
        }, 4000);

        if (fragments.length > 0) {
            clearTimeout(timeout);
            setIsLoading(false);
        }

        return () => clearTimeout(timeout);
    }, [fragments]);


    useEffect(() => {
        if(skipsObjectsRevitProject.length === 0){
            const lastCurrentIndex = localStorage.getItem('LastCurrentIndex');
            setCurrentIndex(Number(lastCurrentIndex))
            localStorage.removeItem('LastCurrentIndex');
            setCheckingSkip(false)
        }

    }, [skipsObjectsRevitProject]);

    const fetchAllSkipsObject = async () =>{
        try {
            if(!arhitectProject) return null
            const response = await getAllSkipsRevitObject(arhitectProject.revit_project_id);
            setAllSkipsObjects(response);
        } catch (error){
            console.error(error)
        }
    }
    useEffect(() => {
        if(arhitectProject && checkingSkip){
            fetchAllSkipsObject()
        }

    }, [arhitectProject, checkingSkip]);

    const handleSkip = async () => {
        try {
            const { id, name } = skipFragmentData;
            if (!id) {
                toast.warn('No fragment selected to skip.');
                return;
            }

            if (checkingSkip) {
                const sortedSkips = [...skipsObjectsRevitProject].sort((a, b) => a - b);
                const currentIndexPosition = sortedSkips.indexOf(currentIndex);

                if (currentIndexPosition === -1 || currentIndexPosition === sortedSkips.length - 1) {
                    toast.info("End of skipped fragments.");
                    setCheckingSkip(false);
                    return;
                }
                toast.warn(`You repeatedly skipped the “${name}” object.`)
                const nextIndex = sortedSkips[currentIndexPosition + 1];
                setCurrentIndex(nextIndex);
                return;
            }

            let nextIndex = currentIndex + 1;

            const isIndexTaken = (index: number) =>
                allFragments.some((fragment) => fragment.currentIndex === index) ||
                skipsObjectsRevitProject.includes(index);

            if (currentFragment !== null) {
                while (nextIndex < externalIds.length && isIndexTaken(nextIndex)) {
                    nextIndex++;
                }
            } else {
                if (!skipsObjectsRevitProject.includes(currentIndex)) {
                    const response = await skipRevitFragment(id, name);
                    if (response === null) {
                        toast.error("Failed to skip the fragment.");
                        return;
                    }
                    setSkipsObjectsRevitProject((prev) => [...prev, currentIndex]);
                }

                while (nextIndex < externalIds.length && isIndexTaken(nextIndex)) {
                    nextIndex++;
                }
            }

            if (nextIndex >= externalIds.length) {
                toast.warn("All fragments have been skipped.");
                setCurrentIndex(Math.min(...skipsObjectsRevitProject));
                return;
            }

            setCurrentIndex(nextIndex);
            toast.info(`You skipped the “${name}” object.`);
            setSelecting(false);
        } catch (error) {
            toast.error("Failed to skip the snippet. Please try again.");
            setCurrentIndex((prev) => Math.max(prev - 1, 0));
        }
    };


    const handleBack = () => {
        if (checkingSkip) {
            const sortedSkips = [...skipsObjectsRevitProject].sort((a, b) => a - b);
            const currentIndexPosition = sortedSkips.indexOf(currentIndex);
            if (currentIndexPosition > 0) {
                setCurrentIndex(sortedSkips[currentIndexPosition - 1]);

                return
            } else {
                toast.info("You have reached the first skipped fragment.");
                return;
            }
        } else {
            setCurrentIndex((prevIndex) => {
                const nextIndex = prevIndex - 1;
                return nextIndex < 0 ? totalObjects - 1 : nextIndex;
            });
        }

        if (!currentFragment) {
            setSelecting(false);
            setSelectedItemTypes([]);
        }
    };



    const handleUpdateExternalIds = (ids: string[]) => {
        setExternalIds(ids);
    };
    const handleDataReady = (addressData: AddressData, fileData: FileData) => {
        setProjectData(addressData);
        setProjectImg(fileData.file);
        if (arhitectProject !== null) {
            const isSame =
                arhitectProject.title === projectData.title &&
                arhitectProject.target_date === projectData.target_date &&
                arhitectProject.address?.full_address === projectData.address.full_address &&
                arhitectProject.address?.latitude === projectData.address.latitude &&
                arhitectProject.address?.longitude === projectData.address.longitude;
            setIsSavedProject(isSame);
        }
    };

    const handleCreateProject = async () => {
        try {
            if (projectData && projectIdByUrn) {
                const body = {
                    address: projectData.address,
                    title: projectData.title,
                    target_date: projectData.target_date,
                    revit_project_id: projectIdByUrn,
                };
                const {project, images} = await createArchitectProject(body, projectImg);
                setArhitectProject(project);
            }
        } catch (error) {
            toast.error("Failed to create a project.  Please try again");
        }
    };

    const handleOpenItemType = async () => {
        if (!arhitectProject) return null

        if (arhitectProject && arhitectProject.data && Array.isArray(arhitectProject.data) && arhitectProject.data.fragments.length > 0) {
            const currentFragmentFromProject = arhitectProject?.data?.fragments.find(
                (fragment) => fragment.current_index === currentIndex
            );

            if (currentFragmentFromProject) {
                setRemoveExistFragmentState(true);
                setIsDeleteOpen(true);
                return;
            }
        }
        const fragmentFromProject = arhitectProject.data.fragments.find((fragment) => fragment.id === selectedFragmentId);
        if (isUpdatedFragment[currentFragment?.currentIndex ?? -1] && fragmentFromProject && fragmentFromProject.id === selectedFragmentId){
            setRemoveExistFragmentState(true);
            setIsDeleteOpen(true);
            return
        }

        const wasSelecting = selecting;
        setSelecting(true);


        try {
            setIsModalLoading(true);
            const response = await fetchItemTypes(searchTerm, String(currentPage), sortDir);
            if (response && response.status === 200) {
                if (!wasSelecting) {
                    setSelectedItemTypes([]);
                    setItemTypeCount({});
                } else {
                    if (selectedItemTypes.length === 0) {
                        setItemTypeCount({});
                    } else {
                        if (currentFragment) {
                            const restoredItemTypeCount: Record<number, number> = {};
                            currentFragment.itemTypes.forEach((itemType: { id: number }) => {
                                restoredItemTypeCount[itemType.id] =
                                    (restoredItemTypeCount[itemType.id] || 0) + 1;
                            });

                            setItemTypeCount(restoredItemTypeCount);
                        }
                    }
                }

                setIsOpen(true);
                setItemTypes(response.data.data);
                setTotalItems(response.data.pagination.total);
            }
        } catch (error) {
            console.error('Failed to load item types:', error);
        } finally {
            setIsModalLoading(false);
        }
    };


    useEffect(() => {
        if (isOpen) {
            handleOpenItemType();
        }
    }, [searchTerm, currentPage, sortDir, isOpen]);

    const handleNextObjectCustom = async (newFragment: AllFragments) => {
        if (!arhitectProject) return null;

        if (selectedItemTypes.length === 0) {
            toast.warning(
                'The fragment cannot remain empty. A minimum of 1 Item Type is required. Please add Item Type or select exist Fragment',
                {
                    autoClose: 4500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: false,
                    progressStyle: { background: 'green' },
                }
            );
            return null;
        }

        const itemTypes = newFragment.itemTypes.map(itemType => ({
            id: itemType.id,
            qty: itemType.qty || 0,
        }));
        if (itemTypes.some(itemType => itemType.qty === 0)) {
            toast.warning(
                'The QTY to order field is required for entry. It is required to enter item type.',
                {
                    autoClose: 4500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: false,
                    progressStyle: { background: 'green' },
                }
            );
            return null;
        }

        const body = {
            title: newFragment.name,
            item_types: itemTypes,
            revit_object_id: skipFragmentData.id,
            current_index: currentIndex,
        };

        try {
            const response = await addCustomFragment(arhitectProject?.id, body);
            if (response !== null) {
                setArhitectProject(response);
                setSelecting(false);
                setSelectedItemTypes([]);

                setAllFragments((prevFragments) => {
                    const existingFragmentIndex = prevFragments.findIndex(fragment => fragment.currentIndex === currentIndex);

                    if (existingFragmentIndex === -1) {
                        return [...prevFragments, newFragment];
                    }

                    const existingFragment = prevFragments[existingFragmentIndex];

                    if (existingFragment.itemTypes.length !== newFragment.itemTypes.length) {
                        const updatedFragments = [...prevFragments];
                        updatedFragments[existingFragmentIndex] = newFragment;
                        return updatedFragments;
                    }

                    const isIdentical = existingFragment.itemTypes.every((item, index) =>
                        item.id === newFragment.itemTypes[index].id &&
                        item.qty === newFragment.itemTypes[index].qty
                    );

                    if (!isIdentical) {
                        const updatedFragments = [...prevFragments];
                        updatedFragments[existingFragmentIndex] = newFragment;
                        return updatedFragments;
                    }

                    return prevFragments;
                });

                if (checkingSkip) {
                    setSkipsObjectsRevitProject(prevSkips =>
                        prevSkips.filter(index => index !== currentIndex)
                    );
                    const sortedSkips = [...skipsObjectsRevitProject].sort((a, b) => a - b);
                    const currentIndexPosition = sortedSkips.indexOf(currentIndex);

                    if (currentIndexPosition !== -1 && currentIndexPosition < sortedSkips.length - 1) {
                        toast.success(`You have successfully recovered the skipped "${skipFragmentData.name}" object.`)
                        setCurrentIndex(sortedSkips[currentIndexPosition + 1]);
                    } else {
                        toast.info("End of skipped fragments.");
                        setCheckingSkip(false);
                    }
                } else {
                    let nextIndex = currentIndex + 1;
                    while (nextIndex < externalIds.length && allFragments.some(fragment => fragment.currentIndex === nextIndex)) {
                        nextIndex++;
                    }

                    if (nextIndex < externalIds.length) {
                        setCurrentIndex(nextIndex);
                    } else {
                        setCurrentIndex(0);
                    }
                }
            }
        } catch (error) {
            toast.error("Failed to add a Fragment to the project");
        }

        setSelecting(false);
    };



    const handleNextExistProject = async () => {
        if (!arhitectProject) return null;
        if (!existDataFragment) {
            toast.warn('You must select the fragment');
            return;
        }

        const existFragment: AllFragments = {
            currentIndex: currentIndex,
            id: existDataFragment.id ?? 0,
            name: existDataFragment.title ?? "",
            custom: false,
            data: finalData ?? [],
            itemTypes: Array.isArray(existDataFragment.data?.item_types)
                ? existDataFragment.data.item_types
                : [],
            revit_object_id: existDataFragment.revit_object_id,
            inProjectFragmentId: existDataFragment.id ?? 0
        };

        const updatedData = existFragment?.data?.map(({ key, value }) => ({ key, value })) || [];

        const body = {
            id: existFragment.id,
            revit_object_id: skipFragmentData.id,
            current_index: existFragment.currentIndex,
            questions: updatedData,
        };

        try {
            const response = await addExistFragment(arhitectProject.id, body);

            if (response) {
                setArhitectProject(response);
                setIsUpdatedFragment({});
                setAllFragments((prevFragments) => {
                    const existingIndex = prevFragments.findIndex(
                        (fragment) => fragment.currentIndex === currentIndex
                    );

                    if (existingIndex !== -1) {
                        const updatedFragments = [...prevFragments];
                        const existingFragment = prevFragments[existingIndex];

                        if (existingFragment.id !== existFragment.id) {
                            updatedFragments[existingIndex] = existFragment;
                            return updatedFragments;
                        }

                        if (JSON.stringify(existingFragment.data) !== JSON.stringify(finalData)) {
                            updatedFragments[existingIndex] = {
                                ...existingFragment,
                                data: finalData ?? [],
                                itemTypes: existFragment.itemTypes,
                            };
                            return updatedFragments;
                        }

                        return prevFragments;
                    }

                    return [...prevFragments, existFragment];
                });

                setExistDataFragment(null);

                if (checkingSkip) {
                    setSkipsObjectsRevitProject(prevSkips =>
                        prevSkips.filter(index => index !== currentIndex)
                    );
                    const sortedSkips = [...skipsObjectsRevitProject].sort((a, b) => a - b);
                    const currentIndexPosition = sortedSkips.indexOf(currentIndex);
                    toast.success(`You have successfully recovered the skipped "${skipFragmentData.name}" object.`)
                    if (currentIndexPosition !== -1 && currentIndexPosition < sortedSkips.length - 1) {
                        setCurrentIndex(sortedSkips[currentIndexPosition + 1]);
                    } else {
                        toast.info("End of skipped fragments.");
                        setCheckingSkip(false);
                    }
                } else {
                    setCurrentIndex((prevIndex) => prevIndex + 1);
                }
            }
        } catch (error) {
            toast.error("Failed to add the fragment to the project.");
        }
    };


    const handleCreateExist = () => {
        if(!arhitectProject) return null;
        const fragmentFromProject = arhitectProject.data.fragments.find((fragment) => fragment.id === selectedFragmentId);
        if (isUpdatedFragment[currentFragment?.currentIndex ?? -1] && fragmentFromProject && fragmentFromProject.id === selectedFragmentId){
            setRemoveCustomFragmentState(true);
            setIsDeleteOpen(true);
            return
        }
        if (fragments.length <= 0) {
            toast.info('You can only create a custom fragment')
            setSelecting(true)
            setIsOpen(true)
            return;
        } else if (arhitectProject && arhitectProject.data && arhitectProject.data.customFragments.length > 0) {
            const currentFragmentFromProject = arhitectProject?.data?.customFragments.find((fragment) => fragment.data.current_index === currentIndex);
            if (currentFragmentFromProject) {
                setRemoveCustomFragmentState(true)
                setIsDeleteOpen(true)
                return
            }
        } else {
            setSelecting(false)
            setIsOpen(false)
        }
    }

    useEffect(() => {
        if (currentFragment?.currentIndex !== currentIndex) {
            setSelectedFragmentId(null)
        } else {
            setSelectedFragmentId(currentFragment.id)
        }
    }, [currentIndex, currentFragment]);

    useEffect(() => {
        const handleUnload = (event: BeforeUnloadEvent) => {
            if (!isSavedProject) {
                event.preventDefault();
                event.returnValue = "Are you sure you want to exit? Unsaved changes may be lost.";
            }
        };
        window.addEventListener("beforeunload", handleUnload);
        return () => {
            window.removeEventListener("beforeunload", handleUnload);
        };
    }, [isSavedProject]);


    useEffect(() => {
        if (removeExistFragmentState) {
            setMessage('Are you sure you want to create a custom Fragment? This will delete the existing Fragment')
        } else if (removeCustomFragmentState) {
            setMessage('Are you sure you want to add an existing Fragment? This will delete the custom Fragment')
        }
    }, [removeExistFragmentState, removeCustomFragmentState])

    const handleSave = async () => {
        if (!arhitectProject) return null
        try {
            if (projectData && projectIdByUrn) {
                const body = {
                    address: projectData.address,
                    title: projectData.title,
                    target_date: projectData.target_date,
                    revit_project_id: projectIdByUrn,
                    current_index: currentIndex,
                };
                const {project, images} = await updateProject(arhitectProject?.id, body, projectImg);
                setArhitectProject(project);
                setIsSavedProject(true)
            }
        } catch (error) {
            toast.error("Failed to create a project. Please try again");
        }
    }
    return (
        <section>
            <div className="wrapper">
                {!isSmallScreen ? (
                    <div className="assistance-placeholder">
                        <p>
                            Home / Project group assistance / {" "}
                            <span className="name-link">Create new project</span>
                        </p>
                    </div>
                ) : (
                    <MobileNavigation
                        links={[
                            {to: '/', label: 'Cashbuild'},
                            {to: '', label: '>'},
                            {to: '/', label: 'Home'},
                            {to: '', label: '>'},
                            {to: '/assistance', label: 'Project assistance'},
                        ]}
                    />
                )}
            </div>

            <CreateArchitectProject
                getProject={arhitectProject}
                onDataReady={handleDataReady}
                fileName={fileName ? fileName : ''}
            />

            <section className="revit-section">
                <div className='revit-section-content'>
                    <RevitComponent
                        urn={urn ? urn : ''}
                        showWizard={showWizard}
                        currentIndex={currentIndex}
                        setTotalObjects={setTotalObjects}
                        updateExternalIds={handleUpdateExternalIds}
                        setExternalIdToDbId={setExternalIdToDbId}
                    />

                    {showWizard && (
                        <>
                            <div className="revit-section-btn-block">
                                <button
                                    className={`revit-section-btn-back  ${!checkingSkip ? currentIndex === 0 : (Math.min(...skipsObjectsRevitProject) === currentIndex) ? 'back-disabled' : ''} revit-btn-step`}
                                    onClick={handleBack}
                                    disabled={!checkingSkip ? currentIndex === 0 : (Math.min(...skipsObjectsRevitProject) === currentIndex)}
                                >
                                    Back
                                </button>
                                <button
                                    className={`revit-section-btn-skip revit-btn-step ${totalObjects === 0 ? 'skip-disabled' : ''}`}
                                    onClick={handleSkip}
                                    disabled={totalObjects === 0}
                                >
                                    Skip
                                </button>
                                <button
                                    className="revit-section-btn-next revit-btn-action"
                                    onClick={() => handleCreateExist()}
                                >
                                    Create from exist fragment
                                </button>
                                <button
                                    onClick={handleOpenItemType}
                                    className="revit-section-btn-create-object revit-btn-action">
                                    <p>
                                        <IconRevitInfo/>
                                    </p>
                                    Create custom object
                                </button>
                            </div>
                            {skipFragmentData && skipFragmentData.name && (
                                <div className="revit-item-types-container-header-text">
                                    <p>Extracted object:</p>
                                    <p className='revit-item-types-container-header-text-extracted'>
                                        {skipFragmentData?.name ? `NAME: ${skipFragmentData.name}` : ''}
                                        {skipFragmentData?.measurements?.length !== undefined && skipFragmentData.measurements.length !== null
                                            ? `, Length: ${skipFragmentData.measurements.length}`
                                            : ''}
                                        {skipFragmentData?.measurements?.area !== undefined && skipFragmentData.measurements.area !== null
                                            ? `, Area: ${skipFragmentData.measurements.area}`
                                            : ''}
                                        {skipFragmentData?.measurements?.volume !== undefined && skipFragmentData.measurements.volume !== null
                                            ? `, Volume: ${skipFragmentData.measurements.volume}`
                                            : ''}
                                        {skipFragmentData?.measurements?.width !== undefined && skipFragmentData.measurements.width !== null
                                            ? `, Width: ${skipFragmentData.measurements.width}`
                                            : ''}
                                    </p>
                                </div>
                            )}
                        </>
                    )}

                    <div>
                        {!selecting && arhitectProject !== null && (
                            <>
                                <div className="revit-measurements-block">
                                    <div className="revit-item-types-inner">
                                        {!selecting &&
                                            fragments.map((fragment, index) => {
                                                const isSelected = (selectedFragmentId === fragment.id);
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`animated-component 
                                                           ${selecting ? "hidden" : "show-from-bottom"}
                                                           ${isUpdatedFragment[currentFragment?.currentIndex ?? -1] && fragment.id === currentFragment?.id ? "updated-fragment" : ""}`}
                                                    >

                                                        <div className="fragment-radio">
                                                            <label className="custom-radio">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isSelected}
                                                                    onChange={() => toggleFragmentSelection(fragment.id, fragment)}/>
                                                                <span className="radio-button"></span>
                                                                <span
                                                                    className="radio-label">Select this fragment</span>
                                                            </label>
                                                        </div>
                                                        <QuestionAnswers
                                                            allFragments={allFragments}
                                                            currentIndex={currentIndex}
                                                            fragment={fragment}
                                                            setFinalData={(data) => {
                                                                if (selectedFragmentId === fragment.id) {
                                                                    setFinalData(data);
                                                                }
                                                            }}
                                                            isSelected={isSelected}/>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                                {fragments.length > 0 && (
                                    <div className='revit-container-next-object'>
                                        <p
                                            onClick={() => handleNextExistProject()}
                                            className='revit-container-next-object-text'>Next object -{'>'}</p>
                                    </div>
                                )}

                            </>
                        )}
                    </div>

                    {selecting && (
                        <ItemTypesSelected
                            currentIndex={currentIndex}
                            handleNextObjectCustom={handleNextObjectCustom}
                            setIsOpen={setIsOpen}
                            handleRemoveItemType={handleRemoveItemType}
                            selectedItemTypes={selectedItemTypes}
                            fragmentData={skipFragmentData}
                            setSelectedItemTypes={setSelectedItemTypes}
                        />
                    )}
                </div>

                <div className={`animated-component ${showWizard ? 'show-from-right' : 'hidden'}`}>
                    <SelectedFragmentsComponent
                        arhitectProject={arhitectProject ? arhitectProject : null}
                        allFragments={allFragments}
                        currentIndex={currentIndex}
                        setArhitectProject={setArhitectProject}
                        setAllFragments={setAllFragments}
                        setSelecting={setSelecting}
                        setCurrentIndex={setCurrentIndex}
                        setIsUpdatedFragment={setIsUpdatedFragment}
                        isUpdatedFragment={isUpdatedFragment}
                    />
                </div>
            </section>

            {projectData.address && projectData.title && projectData.target_date && !arhitectProject && (
                <div className="arhitector-btn-block">
                    {projectData.address.full_address && projectData.title && projectData.target_date && !arhitectProject && (
                        <button
                            title={'Create a project and proceed to work with revit'}
                            className="step-bottom-btn"
                            onClick={() => {
                                handleCreateProject();
                                setCurrentIndex(0);
                                setShowWizard(true);
                            }}
                        >
                            Start wizard
                        </button>
                    )}
                </div>
            )}

            {arhitectProject && (
                <div className="arhitector-btn-block">
                    <button
                        onClick={() => handleSave()}
                        className='cancel-button'>Save
                    </button>
                    <button
                        title={'Create a project and proceed to work with revit'}
                        className="step-bottom-btn"
                        onClick={()=>handleNextStep()}
                    >
                        {checkingSkip ? 'Close skip' : 'Next step'}
                    </button>
                </div>
            )}

            <ItemTypeCustomFragmentSelectModal
                isOpen={isOpen}
                onClose={onClose}
                itemTypes={itemTypes}
                loading={isModalLoading}
                currentPage={currentPage}
                totalItems={totalItems}
                itemsPerPage={5}
                onPageChange={(page) => setCurrentPage(page)}
                onSearch={(query) => setSearchTerm(query)}
                onSortChange={(direction) => setSortDir(direction)}
                totalSearchResults={totalItems}
                initialSelectedItems={selectedItemTypes}
                onItemTypeCountChange={(id, count) => {
                    if (count > 0) {
                        const item = itemTypes.find((item) => item.id === id);
                        if (item) handleAddItemType(item);
                    } else {
                        const item = selectedItemTypes.find((item) => item.id === id);
                        if (item) handleRemoveItemType(item);
                    }
                }}
                itemTypeCount={itemTypeCount}
            />
            {isDeleteOpen && (
                <DeleteModal
                    isOpen={true}
                    onRequestClose={() => setIsDeleteOpen(false)}
                    onConfirm={removeExistFragmentState ? handleRemoveFragmentFromProject : handleRemoveCustomFragmentFromProject}
                    onCancel={() => deleteCansel()}
                    message={message}
                    confirmText="Delete"
                    cancelText="Keep"
                />
            )}

            {skipOpenModal && (
                <CheckingSkip
                    isOpen={true}
                    onRequestClose={() => setSkipOpenModal(false)}
                    onConfirm={skipOnConfirm}
                    onCancel={skipChkecking}
                    message={
                        <>
                            <p>Are you sure you want to take it to the next step?</p>
                            <p>
                                You have <span className="revit-remove-fragment-name">{skipsObjectsRevitProject.length}</span> skipped fragments.
                            </p>
                            <p>
                                Click <strong>Missing</strong> to see the skipped objects or <strong>Next step</strong> to confirm.
                            </p>
                        </>
                    }

                    confirmText="Next step"
                    cancelText="Missing"
                />
            )}

        </section>
    );
}
export default ArhitectPage;