import React, {useState, useEffect, useRef} from 'react';
import Modal from 'react-modal';
import {TItem} from "../../types/TemplateType";
import "./Step2Styles/itemModal.css";
import 'react-toastify/dist/ReactToastify.css';
import {
    toastItemCheckboxFalse,
    toastItemError,
    toastItemTrue
} from "../../components/Toast/AssisCreateToastNotification";
import {
    IconArrowBottom,
    IconArrowTop,
    IconCheckItemAvailable,
    IconReload, IconResetInput,
    IconSearch
} from "../../IconComponents/IconComponents";
import {Loader} from "../../components/Loader/Loader";
import PaginationInModal from "../../components/PaginationAssistance/paginationInModal";
import {Vendor, vendorData} from "../../Enums/VendorEnum";
import ProgressBar from "./ItemProgressBar";

interface ItemSelectionModalProps {
    isOpen: boolean,
    onClose: () => void,
    items: TItem[],
    loading: boolean,
    handleItemClick: (items: TItem[]) => void,
    selectedItems: { itemId: number; user_item_qty: number }[],
    onReload?: () => void,
    currentPage: number,
    totalItems: number,
    itemsPerPage: number,
    onPageChange: (page: number) => void,
    onSearch?: (query: string, page: number) => void;
    totalSearchResults?: number,
    storeId: number
}

const ItemSelectionModal: React.FC<ItemSelectionModalProps> = ({
                                                                   isOpen,
                                                                   onClose,
                                                                   items,
                                                                   loading,
                                                                   handleItemClick,
                                                                   selectedItems,
                                                                   onReload,
                                                                   currentPage,
                                                                   totalItems,
                                                                   itemsPerPage,
                                                                   onPageChange,
                                                                   onSearch,
                                                                   totalSearchResults,
                                                               }) => {
    const [userItemQuantities, setUserItemQuantities] = useState<{ [id: number]: number }>({});
    const [isError, setIsError] = useState(false);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [filteredItems, setFilteredItems] = useState<TItem[]>(items);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | null>(null);
    const isUserAction = useRef(false);
    const [forceRemountKey, setForceRemountKey] = useState<number>(0);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            setForceRemountKey(prev => prev + 1);
            initializeState();
        } else {
            document.body.style.overflow = '';
            resetState();
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen, items]);

    useEffect(() => {
        if (onSearch) {
            if (searchTimer) {
                clearTimeout(searchTimer);
            }
            if (searchTerm) {
                const timer = setTimeout(() => {
                    onSearch(searchTerm, currentPage);
                }, 500);
                setSearchTimer(timer);
            } else {
                onSearch('', currentPage);
            }
        }
    }, [searchTerm]);

    useEffect(() => {
        const totalQty = calculateTotalQty();
        const qty_item_type = items[0]?.qty_item_type || 0;

        if (totalQty === qty_item_type && isUserAction.current) {
            isUserAction.current = false;
        }
    }, [userItemQuantities, items]);

    const resetState = () => {
        setUserItemQuantities({});
        setIsError(false);
        setSearchTerm('');
        setFilteredItems([]);
        setSortDirection('asc');
    };

    const initializeState = () => {
        const initialQuantities = items.reduce((acc: { [x: number]: number }, item: TItem) => {
            const selected = selectedItems.find(selected => selected.itemId === item.id);
            acc[item.id] = selected ? selected.user_item_qty : 0;
            return acc;
        }, {});

        setUserItemQuantities({...initialQuantities});
        setFilteredItems(items);
    };

    const handleQtyChange = (id: number, value: number) => {
        isUserAction.current = true;
        setUserItemQuantities(prevState => ({...prevState, [id]: value}));
    };

    const handleFillOut = (item: TItem) => {
        const qty_item_type = items[0]?.qty_item_type || 0;
        const totalEnteredQty = Object.values(userItemQuantities).reduce((sum, qty) => sum + qty, 0);
        const remainingQty = Math.max(qty_item_type - totalEnteredQty, 0);
        const userInputValue = userItemQuantities[item.id] || 0;

        if (userInputValue > 0) {
            setUserItemQuantities(prevState => ({
                ...prevState,
                [item.id]: 0
            }));
            toastItemCheckboxFalse(`Field "${item.title}" cleared`);
            return;
        }

        if (remainingQty > 0) {
            setUserItemQuantities(prevState => ({
                ...prevState,
                [item.id]: remainingQty
            }));
            toastItemTrue(
                `Field "${item.title}" filled with ${remainingQty} units. Remaining qty distributed.`
            );
        } else {
            toastItemError(`No remaining quantity to distribute.`);
        }
    };



    const handleResetQuantities = () => {
        setUserItemQuantities(
            items.reduce<{ [id: number]: number }>((acc, item) => {
                acc[item.id] = 0;
                return acc;
            }, {})
        );
        toastItemTrue('All input fields have been reset.');
    };

    const calculateTotalQty = () => {
        return Object.values(userItemQuantities).reduce((total, qty) => total + qty, 0);
    };
    const handleSave = () => {
        const updatedItems = items
            .filter(item => userItemQuantities[item.id] > 0)
            .map(item => ({
                ...item,
                user_item_qty: userItemQuantities[item.id] || 0
            }));

        if (updatedItems.length === 0) {
            handleItemClick(updatedItems);
            onClose();
            return;
        }


        handleItemClick(updatedItems);
        onClose();
    };

    const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const highlightMatch = (text: string, match: string) => {
        if (!match) return text;
        const regex = new RegExp(`(${match})`, 'gi');
        return text.replace(regex, (matched) => `<span class="item-selection-modal-highlight">${matched}</span>`);
    };

    const sortedItems = filteredItems.sort((a, b) => {
        return sortDirection === 'asc' ? a.price - b.price : b.price - a.price;
    });

    const totalQty = calculateTotalQty();
    const qty_item_type = items[0]?.qty_item_type || 0;

    const handleClose = () => {
        resetState();
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            key={forceRemountKey}
            onRequestClose={handleClose}
            className="item-selection-modal-content"
            overlayClassName="item-selection-modal-overlay"
            ariaHideApp={false}
        >
            <section className="item-selection-modal-section">
                <div className='item-selection-modal-header'>
                    <div className='item-selection-modal-header-block-text'>
                        <h2 className="item-selection-modal-title">Please, select your product</h2>
                    </div>
                    <button className="item-selection-modal-close-button" onClick={handleClose}>
                        &#x2715;
                    </button>
                    <ProgressBar total={qty_item_type} current={totalQty}/>
                </div>
                <div className='item-selection-modal-search'>
                    <div className='item-selection-modal-search-icon'>
                        <IconSearch/>
                    </div>
                    <input
                        className='item-selection-modal-search-input'
                        placeholder='Search'
                        type='search'
                        value={searchTerm}
                        onChange={handleSearchInput}
                    />
                </div>
                <div className='item-selection-modal-sort-icons'>
                    <div className='item-selection-modal-sort-buttons'>
                        {onReload && (
                            <div className='item-selection-modal-sort-button' onClick={handleResetQuantities}>
                                <IconResetInput/>
                            </div>
                        )}
                        <div className='item-selection-modal-sort-button' onClick={() => setSortDirection('desc')}>
                            <IconArrowTop/>
                        </div>
                        <div className='item-selection-modal-sort-button' onClick={() => setSortDirection('asc')}>
                            <IconArrowBottom/>
                        </div>
                    </div>
                </div>
                {loading ? (
                    <Loader/>
                ) : (
                    <ul className="item-selection-modal-list">
                        {sortedItems.map((item: TItem) => {
                            const isSelected = userItemQuantities[item.id] > 0;
                            const initialQty = userItemQuantities[item.id] || 0;
                            const quantity = item.qty_from_store;

                            return (
                                <li key={item.id} className="item-selection-modal-list-item">
                                    <section className="item-selection-modal-list-item-content">
                                        <div className="item-selection-modal-list-item-image">
                                            <img
                                                className="item-selection-modal-image"
                                                src={
                                                    item.vendor === 'cashbuild'
                                                        ? item.merchant_info?.image ||
                                                        (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                        '/img_vendor/item.png'
                                                        : (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                        '/img_vendor/item.png'
                                                }
                                                alt="Item"
                                            />

                                            <section className="item-selection-modal-list-item-info">
                                                <p
                                                    className="item-selection-modal-item-title"
                                                    dangerouslySetInnerHTML={{
                                                        __html: highlightMatch(item.title, searchTerm)
                                                    }}
                                                />
                                                <p className="item-selection-modal-item-description">
                                                    {item.merchant_info?.description_short ||
                                                        item.merchant_info?.description ||
                                                        item.merchant_info?.meta_description}
                                                </p>
                                                <p className="item-selection-modal-item-price">R {item.price ? Number(item.price).toFixed(2) : '0.00'}</p>
                                            </section>
                                        </div>

                                        <div className='item-selection-modal-action-block'>

                                            <div className='item-packaging'>
                                                {item.packaging && Object.keys(item.packaging).length > 0 ? (
                                                    <>
                                                        <span
                                                            className='item-packaging-value'>{item.packaging[Object.keys(item.packaging)[0]]}</span>
                                                        <p className='item-packaging-key'>{Object.keys(item.packaging)[0]}</p>
                                                    </>
                                                ) : (
                                                    <p className='item-packaging-none'>{item.unit}</p>
                                                )}
                                            </div>

                                            <div className='item-selection-modal-action-block-img'>
                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                    <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                ) : (
                                                    <p className='item-selection-modal-no-vendor'>No vendor</p>
                                                )}
                                            </div>

                                            {quantity === 0 ? (
                                                <>
                                                    <div className='item-selection-modal-quantity-input'>
                                                        <button
                                                            disabled={!isSelected}
                                                            className="item-selection-modal-qty-button minus"
                                                            onClick={() => handleQtyChange(item.id, Math.max(0, initialQty - 1))}>-
                                                        </button>
                                                        <input
                                                            type="number"
                                                            value={initialQty > 0 ? initialQty : ''}
                                                            className="item-selection-modal-qty-input-field"
                                                            onChange={(e) => {
                                                                const newValue = e.target.value;
                                                                handleQtyChange(item.id, Math.max(0, Number(newValue)));
                                                            }}
                                                            min={0}
                                                        />
                                                        <button
                                                            disabled={!isSelected}
                                                            className="item-selection-modal-qty-button plus"
                                                            onClick={() => handleQtyChange(item.id, initialQty + 1)}>+
                                                        </button>
                                                    </div>
                                                    <button className="item-selection-modal-button out-of-stock">
                                                        Out of stock
                                                    </button>
                                                </>
                                            ) : (
                                                <div className='item-selection-modal-quantity-input'>
                                                    <button
                                                        className="item-selection-modal-qty-button minus"
                                                        onClick={() => handleQtyChange(item.id, Math.max(0, initialQty - 1))}>-
                                                    </button>
                                                    <input
                                                        type="number"
                                                        value={initialQty > 0 ? initialQty : ''}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            handleQtyChange(item.id, Math.max(0, Number(newValue)));
                                                        }}
                                                        className="item-selection-modal-qty-input-field"
                                                        min={0}
                                                    />
                                                    <button
                                                        className="item-selection-modal-qty-button plus"
                                                        onClick={() => handleQtyChange(item.id, initialQty + 1)}>+
                                                    </button>
                                                </div>
                                            )}

                                                <button
                                                    onClick={() => handleFillOut(item)}
                                                    disabled={items.length > 1 ? (totalQty >= qty_item_type && initialQty === 0) : false}
                                                    className={`item-selection-modal-button ${
                                                        items.length > 1 && totalQty >= qty_item_type && initialQty === 0 ? 'disabled-item' : 'add-item'
                                                    }`}
                                                >
                                                    {userItemQuantities[item.id] > 0 ? 'Clear' : 'Fill'}
                                                </button>


                                            {typeof (item.qty_from_store) === 'string' ? (
                                                <button className="item-selection-modal-button out-of-stock">
                                                    No limit
                                                </button>
                                            ) : (
                                                <div className='item-available-block'>
                                                    <div><IconCheckItemAvailable/></div>
                                                    <p>{item.qty_from_store}</p>
                                                    <p>available</p>
                                                </div>
                                            )}
                                        </div>

                                    </section>
                                </li>
                            );
                        })}
                    </ul>
                )}
                <div className='item-selection-modal-save-button-block'>
                    <div>
                        <PaginationInModal
                            perPage={itemsPerPage}
                            total={searchTerm ? totalSearchResults : totalItems}
                            paginate={onPageChange}
                            currentPage={currentPage}
                        />
                    </div>
                    <button onClick={handleSave} className="item-selection-modal-save-button">Confirm selection</button>
                </div>
            </section>
        </Modal>
    );
};

export default ItemSelectionModal;
