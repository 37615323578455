import React, {useCallback, useEffect, useState} from 'react';
import {IconSettingsStep2Products} from "../../../IconComponents/IconComponents";
import {BOMItemType, BOMProject} from "../../../types/ProjectBOMInterface";
import {Fragment, ItemType, TItem} from "../../../types/TemplateType";
import {addItemsToFragmentsByLink, get, postBomEditItems} from "../../../api/APIWraper";
import FragmentComponentModal from "./FragmentComponentModal";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";

interface FragmentsComponentProps {
    fragments: Fragment[];
    onToggle: (fragment: string) => void;
    replace: boolean;
    selectedItemsStep3: { [key: string]: BOMItemType };
    selectedItems: { [key: string]: any };
    setProjectBom: React.Dispatch<React.SetStateAction<BOMProject | null>>;
    onSelectedFragmentsChange: (selectedFragments: Fragment[]) => void;
}

const FragmentsComponent: React.FC<FragmentsComponentProps> = ({
                                                                   fragments,
                                                                   onToggle,
                                                                   replace,
                                                                   setProjectBom,
                                                                   onSelectedFragmentsChange
                                                               }) => {
    const [fragment, setFragment] = useState<Fragment[]>(fragments);
    const [itemsFragment, setItemsFragment] = useState<TItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [itemTypeId, setItemTypeId] = useState<number | null>(null);
    const [itemTypeIndex, setItemTypeIndex] = useState<number | null>(null);
    const [fragmentId, setFragmentId] = useState<number | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortDir, setSortDir] = useState<string>('asc');
    const [page, setPage] = useState<number>(1);
    const [isModalOpenFragment, setIsModalOpenFragment] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalSearchResults, setTotalSearchResults] = useState<number>(0);
    const [selectedItemsByItemType, setSelectedItemsByItemType] = useState<{ [key: string]: TItem[] }>({});
    const {link} = useParams<{ link: string }>();
    const [itemTypeQtyByFragment, setItemTypeQtyByFragment] = useState<number>(0)
    const [selectedFragments, setSelectedFragments] = useState<Fragment[]>([]);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [totalPrices, setTotalPrices] = useState<{ [key: string]: number }>({});
    const [fragmentReplaceState, setFragmentReplaceState] = useState<{ [key: number]: boolean }>({});

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (itemTypeId !== null && isModalOpenFragment) {
            fetchItems();
        }
    }, [page, searchTerm, sortDir, itemTypeId, isModalOpenFragment]);

    useEffect(() => {
        const storageKey = 'selectedFragmentBom';
        const data = JSON.parse(localStorage.getItem(storageKey) || '[]');
        setSelectedFragments(data);
    }, [onSelectedFragmentsChange]);

    useEffect(() => {
        const initialSelectedItems: { [key: string]: TItem[] } = {};

        fragments.forEach((fragment) => {
            if (fragment.data && fragment.data.item_types) {
                (fragment.data.item_types).forEach((itemType: ItemType, itemTypeIndex: number) => {
                    if (itemType.items && itemType.items.length > 0) {
                        const key = `${fragment.id}-${itemType.id}-${itemTypeIndex}`;
                        initialSelectedItems[key] = itemType.items;
                    }
                });
            }
        });

        setSelectedItemsByItemType(initialSelectedItems);
    }, [fragments]);

    const handleOpenModal = async (id: number, index: number, qty: number, fragmentId: number) => {
        setItemTypeId(id);
        setItemTypeIndex(index);
        setFragmentId(fragmentId);
        setItemTypeQtyByFragment(qty);
        setIsModalOpenFragment(true);

        const fragment = fragments.find(f => f.id === fragmentId);
        if (fragment) {
            const itemType = (fragment.data.item_types).find(it => it.id === id);
            if (itemType) {
                setItemsFragment(itemType.items || []);
            }
        }
    };

    const handleCloseModal = () => {
        setIsModalOpenFragment(false);
        setFragmentId(null);
    };

    const fetchItems = useCallback(async () => {
        if (itemTypeId === null) {
            return;
        }
        setIsLoading(true);

        let url = '';
        url = searchTerm
            ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemTypeId}?page=${page}&perPage=5&title=${encodeURIComponent(searchTerm)}&sortOrder=${sortDir}`
            : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemTypeId}?page=${page}&perPage=5&sortOrder=${sortDir}`;

        setItemsFragment([]);

        try {
            const response = await get(url);
            setItemsFragment(response.data.items.data);
            setTotalItems(response.data.items.pagination.lastPage);
            setTotalSearchResults(response.data.items.pagination.total);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false)
            console.error("Error when including elements\n:", error);
        }
    }, [page, searchTerm, sortDir, itemTypeId]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setPage(1);
    };

    const handleSortChange = () => {
        setSortDir(prevSortDir => (prevSortDir === 'asc' ? 'desc' : 'asc'));
        setPage(1);
    };

    const handleSelectItems = (selectedItems: TItem[]) => {
        if (itemTypeId !== null && itemTypeIndex !== null && fragmentId !== null) {
            const key = `${fragmentId}-${itemTypeId}-${itemTypeIndex}`;
            setSelectedItemsByItemType((prev) => ({
                ...prev,
                [key]: selectedItems,
            }));
        }

        handleCloseModal();
    };

    const addItemToFragment = async () => {
        const fragmentsToSave: {
            fragment_id: number;
            fragment_index: number;
            item_type_id: number;
            item_type_index: number;
            items: { id: number; qty: number }[];
        }[] = [];

        fragment.forEach((fragmentData, fragmentIndex) => {
            if (fragmentData.data && fragmentData.data.item_types) {
                (fragmentData.data.item_types).forEach((itemType, itemTypeIndex) => {
                    const key = `${fragmentData.id}-${itemType.id}-${itemTypeIndex}`;
                    const selectedItems = selectedItemsByItemType[key] || [];

                    const items = selectedItems.map((item) => ({
                        id: item.id,
                        qty: item.qty,
                    }));

                    if (items.length > 0) {
                        fragmentsToSave.push({
                            fragment_id: fragmentData.id,
                            fragment_index: fragmentIndex,
                            item_type_id: itemType.id,
                            item_type_index: itemTypeIndex,
                            items,
                        });
                    }
                });
            }
        });

        const body = {
            fragments: fragmentsToSave,
        };

        return await addItemsToFragmentsByLink(link, body)
    };

    const handleSave = async (fragmentIndex:number) => {
        const responseAddItemFragment = await addItemToFragment()
        if (responseAddItemFragment.status === 200 || responseAddItemFragment.status === 201) {

            const fragmentsToSave: {
                fragment_id: number;
                fragment_index: number;
                item_type_id: number;
                item_type_index: number;
                item_id: number;
                ordered_qty: number;
            }[] = [];

            fragment.forEach((fragmentData, fragmentIndex) => {
                if (fragmentData.data && fragmentData.data.item_types) {
                    (fragmentData.data.item_types).forEach((itemType, itemTypeIndex) => {
                        const key = `${fragmentData.id}-${itemType.id}-${itemTypeIndex}`;
                        const selectedItems = selectedItemsByItemType[key] || [];

                        selectedItems.forEach((item) => {
                            fragmentsToSave.push({
                                fragment_id: fragmentData.id,
                                fragment_index: fragmentIndex,
                                item_type_id: itemType.id,
                                item_type_index: itemTypeIndex,
                                item_id: item.id,
                                ordered_qty: item.ordered_qty || 0,
                            });
                        });
                    });
                }
            });

            const body = {
                fragments: fragmentsToSave,
            };

            try {
                const response = await postBomEditItems(body, link);
                if (response.status === 200) {
                    setFragment(prevFragment => {
                        return prevFragment.map((fragment) => {
                            return {
                                ...fragment,
                                data: {
                                    ...fragment.data,
                                    item_types: (fragment.data.item_types).map((itemType, itemTypeIndex) => {
                                        const key = `${fragment.id}-${itemType.id}-${itemTypeIndex}`;
                                        return {
                                            ...itemType,
                                            items: selectedItemsByItemType[key] || itemType.items,
                                        };
                                    }),
                                },
                            } as unknown as Fragment;
                        });
                    });
                    setProjectBom(response.data)
                    toast.success('You have successfully replaced the products in fragments');
                    handleToggleFragment(fragmentIndex)
                }
            } catch (error) {
                console.error("Error saving items:", error);
                toast.error('An error occurred while saving the items.');
            }
        } else {
            console.error('Failed to save project\n')
        }
    };

    const handleToggleFragment = (fragmentIndex: number) => {
        setFragmentReplaceState((prevState) => ({
            ...prevState,
            [fragmentIndex]: !prevState[fragmentIndex],
        }));
        const fragmentData = fragments[fragmentIndex];
        if (fragmentData.data && fragmentData.data.item_types) {
            const initialSelectedItems: { [key: string]: TItem[] } = {};
            (fragmentData.data.item_types).forEach((itemType: ItemType, itemTypeIndex: number) => {
                if (itemType.items && itemType.items.length > 0) {
                    const key = `${fragmentData.id}-${itemType.id}-${itemTypeIndex}`;
                    initialSelectedItems[key] = itemType.items;
                }
            });
            setSelectedItemsByItemType((prevSelectedItems) => ({
                ...prevSelectedItems,
                ...initialSelectedItems,
            }));
        }
        onToggle('Fragment');
    };

    const recalculateTotalPrice = () => {
        const storageKey = 'selectedFragmentBom';
        const storedFragments: Fragment[] = JSON.parse(localStorage.getItem(storageKey) || '[]');
        const prices: { [key: string]: number } = {};

        storedFragments.forEach((fragment) => {
            let fragmentTotal = 0;

            (fragment.data?.item_types || []).forEach((itemType) => {
                (itemType.items || []).forEach((item) => {
                    const qty = item.qty || 0;
                    const price = parseFloat(String(item.price || 0));
                    fragmentTotal += qty * price;
                });
            });

            prices[fragment.id] = fragmentTotal;
        });

        setTotalPrices(prices);
    };

    const isItemChecked = (
        fragmentId: number,
        itemTypeId: number,
        itemTypeIndex: number,
        itemId: number
    ): boolean => {
        const storageKey = 'selectedFragmentBom';
        const existingData: Fragment[] = JSON.parse(localStorage.getItem(storageKey) || '[]');

        const fragment = existingData.find((frag) => frag.id === fragmentId);
        if (!fragment) return false;

        const itemType = (fragment.data.item_types).find((type, idx) => {
            return type.id === itemTypeId && idx === itemTypeIndex;
        });

        return !!itemType?.items.some((item) => item.id === itemId);
    };

    const handleToggleItemSelection = (
        fragment: Fragment,
        itemTypeId: number,
        itemTypeIndex: number,
        selectedItem: TItem
    ) => {
        const storageKey = 'selectedFragmentBom';
        const existingData: Fragment[] = JSON.parse(localStorage.getItem(storageKey) || '[]');

        const fragmentIndex = existingData.findIndex((f) => f.id === fragment.id);

        let updatedFragment: Fragment;

        if (fragmentIndex !== -1) {
            updatedFragment = {...existingData[fragmentIndex]};
        } else {
            updatedFragment = {
                ...fragment,
                data: {
                    ...fragment.data,
                    item_types: (fragment.data.item_types).map((itemType) => ({
                        ...itemType,
                        items: [],
                    })),
                },
            };
        }

        updatedFragment.data.item_types = (updatedFragment.data.item_types).map(
            (itemType, idx) => {
                if (itemType.id === itemTypeId && idx === itemTypeIndex) {
                    const existingItems = itemType.items || [];
                    const itemExists = existingItems.some((item) => item.id === selectedItem.id);

                    const updatedItems = itemExists
                        ? existingItems.filter((item) => item.id !== selectedItem.id)
                        : [...existingItems, selectedItem];

                    return {
                        ...itemType,
                        items: updatedItems,
                    };
                }
                return itemType;
            }
        );

        const hasItems = updatedFragment.data.item_types.some(
            (itemType: ItemType) => itemType.items && itemType.items.length > 0
        );

        const updatedFragments = [...existingData];

        if (hasItems) {
            if (fragmentIndex !== -1) {
                updatedFragments[fragmentIndex] = updatedFragment;
            } else {
                updatedFragments.push(updatedFragment);
            }
        } else {
            if (fragmentIndex !== -1) {
                updatedFragments.splice(fragmentIndex, 1);
            }
        }

        localStorage.setItem(storageKey, JSON.stringify(updatedFragments));
        setSelectedFragments(updatedFragments);
        onSelectedFragmentsChange(updatedFragments);

        if (hasItems) {
            toast.success(`Item ${selectedItem.title} toggled in fragment ${fragment.title}`);
        } else {
            toast.info(`Item ${selectedItem.title} removed from fragment ${fragment.title}`);
        }

    };


    const handleAddProductsToCart = useCallback(
        (fragment: Fragment) => {
            const storageKey = 'selectedFragmentBom';
            const existingData: Fragment[] = JSON.parse(localStorage.getItem(storageKey) || '[]');

            let updatedFragments = [...existingData];
            const fragmentIndex = existingData.findIndex((f: Fragment) => f.id === fragment.id);

            if (fragmentIndex === -1) {
                updatedFragments.push(fragment);
                toast.success(`Products from fragment ${fragment.title} added to cart.`);
            } else {
                updatedFragments.splice(fragmentIndex, 1);
                toast.info(`Products from fragment ${fragment.title} removed from cart.`);
            }

            localStorage.setItem(storageKey, JSON.stringify(updatedFragments));
            setSelectedFragments(updatedFragments);
            onSelectedFragmentsChange(updatedFragments)
        },
        [setSelectedFragments, onSelectedFragmentsChange]
    );

    useEffect(() => {
        recalculateTotalPrice();
    }, [selectedFragments]);

    const renderHeaderList = () => (
        <div className='step2-bom-header-info'>
            <p className='step2-bom-item-title-checkbox-info'>Product</p>
            <p className='step2-bom-calculated-qty-info'>Calculated QTY</p>
            <p className='step2-bom-qty-ordered-info'>QTY ordered</p>
            <p className='step2-bom-price-info'>Unit price</p>
            <p className='step2-bom-total-info'>Total</p>
            <p className='step2-bom-total-orderd-info'>Total ordered</p>
        </div>
    );

    return (
        <>
            {fragment.map((fragment, fragmentIndex) => {
                const totalPriceKey = fragment.id;
                const isReplace = fragmentReplaceState[fragmentIndex] || false;

                return (
                    <div className='step2-content-block' key={fragment.id}>
                        <div className='step2-bom-bom-project-title'>
                            <p className='step2-bom-title'>{fragment.title}</p>
                            <div onClick={() => handleToggleFragment(fragmentIndex)} className='material-setting-block'>
                                <div><IconSettingsStep2Products/></div>
                                <p>Select Products</p>
                            </div>
                        </div>

                        {renderHeaderList()}

                        {(fragment.data.item_types).map((itemType: ItemType, itemTypeIndex: number) => {
                            return(
                            <div key={`${fragment.id}-${itemType.id}`}>
                                <div className='step2-bom-items'>
                                    <div className='step2-bom-item-title-checkbox'>
                                        {isReplace ? (
                                            <div
                                                className='step2-bom-item-title-replace'
                                                onClick={() => handleOpenModal(itemType.id, itemTypeIndex, itemType.qty, fragment.id)}
                                            >
                                                <p className='step2-bom-item-title'>{itemType.title}</p>
                                                <span className={`arrow-right`}></span>
                                            </div>
                                        ) : (
                                            <p className='step2-bom-item-title'>{itemType.title}</p>
                                        )}
                                    </div>
                                    <p className='step2-bom-calculated-qty'>{itemType.qty} ({itemType.unit ? itemType.unit : 'psc'})</p>
                                    <p className='step2-bom-qty-ordered'>{itemType.ordered_qty || 0}
                                        <span>({itemType.unit ? itemType.unit : 'psc'})</span>
                                    </p>
                                    <p className='step2-bom-price'>-</p>
                                    <p className='step2-bom-total'>-</p>
                                    <p className='step2-bom-total-orderd'>-</p>
                                </div>

                                {(selectedItemsByItemType[`${fragment.id}-${itemType.id}-${itemTypeIndex}`] || []).map((item, itemIndex) => {
                                    const orderedQty = item.ordered_qty || 0
                                    const orderedQtyPackaging = orderedQty * (item.packaging &&
                                        Array.isArray(item.packaging) && item.packaging.length === 0
                                            ? 1
                                            : item.packaging && typeof item.packaging === 'object'
                                                ? item.packaging[Object.keys(item.packaging)[0]] || 1
                                                : 1
                                    );
                                    return (
                                        <div key={`${fragment.id}-${itemType.id}-${itemIndex}`}
                                             className='step2-bom-items'>
                                            <div className='step2-bom-item-title-checkbox'>
                                                {isReplace ? (
                                                    <p className='step2-bom-item-title'>{item.title}</p>
                                                ) : (
                                                    <>
                                                        <input
                                                            type="checkbox"
                                                            className="step2-bom-input-checkbox"
                                                            checked={isItemChecked(fragment.id, itemType.id, itemTypeIndex, item.id)}
                                                            onChange={() =>
                                                                handleToggleItemSelection(fragment, itemType.id, itemTypeIndex, item)
                                                            }
                                                        />
                                                        <p className='step2-bom-item-title'>{item.title}</p>
                                                    </>
                                                )}
                                            </div>
                                            <p className='step2-bom-calculated-qty'>{item.qty || 0} <span>(psc)</span>
                                            </p>
                                            <p className='step2-bom-qty-ordered'>{item.ordered_qty || 0}(psc)/{orderedQtyPackaging}({Object.keys(item.packaging)[0] || (item.unit ? item.unit : 'psc')})
                                            </p>
                                            <p className='step2-bom-price'>R {parseFloat(String(item.price)).toFixed(2)}</p>
                                            <p className='step2-bom-total'>R {(parseFloat(String(item.price)) * (item.qty || 0)).toFixed(2)}</p>
                                            <p className='step2-bom-total-orderd'>R {(parseFloat(String(item.price)) * (item.qty || 0)).toFixed(2)}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        )})}
                        {isReplace ? (
                            <div className='save-button-step2bom-block'>
                                <button onClick={() => handleSave(fragmentIndex)} className='save-button-step2bom'>
                                    Save
                                </button>
                            </div>
                        ) : (
                            <div className='step3-bom-bottom-total-block-price'>
                                <p>Total:</p>
                                {isSmallScreen && (<span className="dashed-line"></span>)}
                                <span>R {(totalPrices[totalPriceKey] || 0).toFixed(2)}</span>
                                <button
                                    onClick={() => handleAddProductsToCart(fragment)}
                                    className='step2-bottom-block-btn-cart-list'>
                                    Add products to cart
                                </button>
                            </div>
                        )}

                    </div>
                );
            })}
            <FragmentComponentModal
                isLoading={isLoading}
                isOpen={isModalOpenFragment}
                onClose={handleCloseModal}
                items={itemsFragment}
                currentPage={page}
                totalItems={totalItems}
                itemsPerPage={5}
                onPageChange={setPage}
                onSortChange={handleSortChange}
                onSearch={handleSearchChange}
                itemTypeQtyByFragment={itemTypeQtyByFragment}
                totalSearchResults={totalSearchResults}
                initialSelectedItems={
                    itemTypeId !== null && itemTypeIndex !== null && fragmentId !== null
                        ? selectedItemsByItemType[`${fragmentId}-${itemTypeId}-${itemTypeIndex}`] || []
                        : []
                }
                onSelectItems={handleSelectItems}
            />
        </>
    )
};

export default FragmentsComponent;
