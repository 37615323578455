export enum UserRoleEnum {
    SAU = "SAU",
    MAU = "MAU",
    Customer = "Customer",
    Contractor = "Contractor",
    Architector = "Architector",
}

export const roleBom = (): boolean => {
    const role = localStorage.getItem('role') as UserRoleEnum;
    return role === UserRoleEnum.SAU || role === UserRoleEnum.Contractor;
};

export const roleArhitector = (): boolean => {
    const role = localStorage.getItem('role') as UserRoleEnum;
    return role === UserRoleEnum.SAU || role === UserRoleEnum.Architector;
};
