import React from 'react';
import './ItemProgressBar.css'
interface ProgressBarProps {
    total: number;
    current: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ total, current }) => {
    const percentage = Math.min((current / total) * 100, 100);

    return (
        <div className='item-progress-bar'>
            <div className='item-progress-bar-scale-part'>
                <div className='item-progress-bar-scale-fill' style={{
                    width: `${percentage}%`,
                }}></div>
            </div>
            <span className='item-progress-bar-scale-text'>
                {current} of {total} selected
            </span>
        </div>
    );
};

export default ProgressBar;
