import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import '../../components/Style List View/style.css';
import ElementList from "../../components/ElementList/ElementList";
import {useNavigate, useParams} from "react-router-dom";
import AddMemberModal from "../../components/Modals/ModalAddNewMember/AddNewMember";
import {RootState} from "../../store";
import {Loader} from "../../components/Loader/Loader";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {IconUsersOnPage} from "../../IconComponents/IconComponents";
import ArchitectUserList from "../ProjectsAllArchitect/ArchitectUserList";
import ArchitectProjectDetailsHeader from "../ProjectsAllArchitect/ArchitectProjectDetails";
import {fetchArchitectProjectById, updateProjectArchitectById} from "../../api/APIWraper";
import {toast} from "react-toastify";
import {ArchitectProject} from "../../types/ArhitectProject";

const ArchitectProjectEdit: React.FC<PropsFromRedux> = ({
                                                            isAuthenticated
                                                        }) => {
    const {projectId} = useParams<{ projectId: string }>();
    const [project,setProject] = useState<ArchitectProject>();
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
    const [isEditingDate, setIsEditingDate] = useState<boolean>(false);
    const [newTitle, setNewTitle] = useState<string>('');
    const [targetDate, setTargetDate] = useState<string>('');
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    useEffect(() => {
        if (isAuthenticated) {
            getProject()
        } else {
            navigate('/home')
        }
    }, [projectId, isAuthenticated]);


    const getProject = async ()=>{
        if (projectId) {
            setIsFetching(true)
            const response = await fetchArchitectProjectById(Number(projectId));
            if(response.status === 200){
                setProject(response.data);
                setIsFetching(false)
            } else {
                toast.error('Failed to fetch project');
                setIsFetching(false)
            }
        }
    }

    useEffect(() => {
        if (project) {
            setNewTitle(project.title);
            setTargetDate(project.target_date ? project.target_date : project.updated_at);
        }
    }, [project]);


    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleEditTitleClick = () => {
        setIsEditingTitle(true);
        setIsEditingDate(false);
    };

    const handleEditDateClick = () => {
        setIsEditingDate(true);
        setIsEditingTitle(false);
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewTitle(e.target.value);
    };

    const handleTargetDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTargetDate(e.target.value);
    };

    const handleBlur = () => {
        setIsEditingTitle(false);
        setIsEditingDate(false);
    };

    const handleSave = async () => {
        if (!project) return;

        const updateData: { title?: string; target_date?: string } = {};

        if (newTitle !== project.title) {
            updateData.title = newTitle;
        }
        if (targetDate !== project.target_date) {
            updateData.target_date = targetDate;
        }

        if (Object.keys(updateData).length === 0) {
            toast.info('No changes to save.');
            return;
        }

        try {
            const body = {
                projectId: project.id,
                title: updateData.title ?? project.title,
                target_date: updateData.target_date ?? project.target_date,
            };

            const response = await updateProjectArchitectById(Number(projectId), body);

            if (response.status === 200 && projectId) {
                toast.success('Project updated successfully!');
                setProject(response.data);
            } else {
                throw new Error('Failed to update the project');
            }
        } catch (error) {
            toast.error('Failed to update the project.');
        }
    };



    if (isFetching) return <Loader/>
    const columnsWidthClasses = ['width-user-username', 'width-user-email',
        "width-user-address", "width-user-project-group", "width-user-permission", "width-user-action-btn"];

    return (
        <>
            {isSmallScreen && (
                <MobileNavigation
                    links={[
                        {to: '/', label: 'Cashbuild'},
                        {to: '', label: '>'},
                        {to: '/', label: 'Home'},
                        {to: '', label: '>'},
                        {to: '/assistance', label: 'Project assistance'},
                        {to: '', label: '>'},
                        {to: '/team', label: 'Project edit'}
                    ]}
                >
                </MobileNavigation>
            )}
            <div>
                <div className='mh'>
                    {project && (
                        <ArchitectProjectDetailsHeader
                            key={project.id}
                            project={project}
                            isEditingTitle={isEditingTitle}
                            isEditingDate={isEditingDate}
                            newTitle={newTitle}
                            targetDate={targetDate}
                            handleEditTitleClick={handleEditTitleClick}
                            handleEditDateClick={handleEditDateClick}
                            handleTitleChange={handleTitleChange}
                            handleTargetDateChange={handleTargetDateChange}
                            handleBlur={handleBlur}
                            isSmallScreen={isSmallScreen}
                        />
                    )}
                </div>
                <div className='name-content-users'>
                    <div className='name-content-users-block'>
                        {isSmallScreen ? (
                            <div className='name-content-users-mobile'>
                                <p>Users</p>
                                <IconUsersOnPage/>
                            </div>
                        ) : (
                            <><p>Users</p><IconUsersOnPage/></>
                        )}
                    </div>
                    <button onClick={() => setIsModalOpen(true)} className='mh-btn-member-users'><span
                        className='add-member-plus'>+</span> Add new member
                    </button>
                    <AddMemberModal
                        pageTeamManagement={false}
                        isOpen={isModalOpen}
                        onRequestClose={() => setIsModalOpen(false)}
                        projectId={projectId}
                        onPermissionsUpdated={() => getProject()}
                        showProjects={false}
                    />
                </div>
                {!isSmallScreen && (
                    <ElementList columnsWidthClasses={columnsWidthClasses}
                                 columns={['User', 'E-mail', 'Address', 'Project group', 'Access rights', 'Action']}
                                 isShow={false}/>
                )}
                {project && project.permissions && (
                    <ArchitectUserList isSmallScreen={isSmallScreen} project={project} setProject={setProject}/>
                )}
                <div className='btn-save'>
                    <button onClick={handleSave} className='btn-save-edits'>Save edits</button>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    isAuthenticated: state.auth.isAuthenticated
});


const connector = connect(mapStateToProps, null);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ArchitectProjectEdit);