import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {ItemType, TItem, TMerchanInfo} from '../../../types/TemplateType';
import {Project} from '../../../types/ProjectTypes';
import {get, patch} from '../../../api/APIWraper';
import {updateLocalTemplate} from '../../../Slice/LocalTemplate/LocalTemplateSlice';
import ItemSelectionModal from '../ItemSelectionModal';
import ILaborItemType from '../../../Interfaces/ProjectInterfaces/IlaborItemType';
import { Vendor, vendorData} from "../../../Enums/VendorEnum";
import {filterLaborAndToolsItemTypes} from "../../../utils/FilterAndSetLaborToolItemTypes";
import {IconArrowLightStpe2} from "../../../IconComponents/IconComponents";

interface ItemTypeListCalculationProps {
    laborItemTypes: ILaborItemType[],
    templateIndex: number,
    templateId: number,
    project: Project,
    onUpdateProject: (newProject: Project) => void,
    onToggle: () => void,
    relatedProjectItemTypes?: ItemType[]
}

interface SelectedItemInfo {
    vendor: Vendor | null;
    merchant_info: TMerchanInfo;
    user_item_qty?: number;
    item_packaging_qty?:number
    item_packaging_value?:string;
    title: string;
    id: number;
    qty: number;
    ordered_qty: number;
    unit: string;
    price: number;
}

interface SelectedItem {
    itemTypeId: number;
    itemId: number;
    index: number;
    user_item_qty: number;
    item_packaging_qty:number
    item_packaging_value:string;
    clone_id: string;
}
interface GroupedItem {
    item_type_id: number;
    item_ids: { id: number, qty: number }[];
    template_index: number;
    item_type_index: number;
    template_id: number;
    fragment_id: number;
    fragment_index: number;
}

interface ItemTypeWithIndex extends ItemType {
    originalIndex: number;
}



const LaborItemTypeListCalculation: React.FC<ItemTypeListCalculationProps & PropsFromRedux> = ({
                                                                                                   laborItemTypes,
                                                                                                   project,
                                                                                                   onUpdateProject,
                                                                                                   onToggle,
                                                                                                   templateId,
                                                                                                   templateIndex
                                                                                               }) => {
    const [selectedItemTypeIndex, setSelectedItemTypeIndex] = useState<number | null>(null);
    const [items, setItems] = useState<TItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedItemInfos, setSelectedItemInfos] = useState<{ [id: string]: SelectedItemInfo }>({});
    const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [debouncedOrderedQty, setDebouncedOrderedQty] = useState<{ [id: string]: string }>({});
    const [parsedItemTypes, setParsedItemTypes] = useState<{ [index: number]: any }>({});

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [visibilityMap, setVisibilityMap] = useState<{ [key: string]: boolean }>({});

    const generateItemTypeKey = (itemType: ItemTypeWithIndex,idx:number) => {
        return `${itemType.id}-${idx}-${itemType.clone_id}-${itemType.title}`;
    };


    const toggleVisibility = (itemTypeKey: string) => {
        setVisibilityMap((prevMap) => ({
            ...prevMap,
            [itemTypeKey]: !prevMap[itemTypeKey],
        }));
    };

    const isVisible = (itemTypeKey: string) => {
        return visibilityMap[itemTypeKey] || false;
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };


    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const mergeLaborItemTypes = () => {
        const mergedItemsMap: { [key: number]: ILaborItemType } = {};

        laborItemTypes.forEach((item) => {
            const itemTypeId = item.item_type.id;

            if (mergedItemsMap[itemTypeId]) {
                const existingItemType = mergedItemsMap[itemTypeId];

                item.item_type.items?.forEach((laborItem) => {
                    const existingLaborItem = existingItemType.item_type.items?.find(
                        (li) => li.id === laborItem.id
                    );

                    if (existingLaborItem) {
                        existingLaborItem.qty += laborItem.qty;
                        existingLaborItem.ordered_qty += laborItem.ordered_qty;
                    } else {
                        existingItemType.item_type.items?.push({ ...laborItem });
                    }
                });
            } else {
                mergedItemsMap[itemTypeId] = JSON.parse(JSON.stringify(item));
            }
        });

        return Object.values(mergedItemsMap);
    };

    useEffect(() => {
        const mergedItemTypes = mergeLaborItemTypes();

        const initialSelectedItems = mergedItemTypes.flatMap((laborItem, idx) =>
            (laborItem.item_type.items || []).map((item, itemIdx) => ({
                itemTypeId: laborItem.item_type.id,
                itemId: item.id,
                index: idx,
                user_item_qty: item.qty,
                item_packaging_qty:item.packaging[Object.keys(item.packaging)[0]] || 1,
                item_packaging_value:Object.keys(item.packaging)[0] || 'each',
                clone_id: `${item.id}-${idx}-${itemIdx}`
            }))
        );

        setSelectedItems(initialSelectedItems);

        const initialSelectedItemInfos = initialSelectedItems.reduce((acc, selectedItem) => {
            const laborItemType = mergedItemTypes[selectedItem.index].item_type;
            const item = laborItemType.items.find((it) => it.id === selectedItem.itemId);
            if (laborItemType && item) {
                acc[selectedItem.clone_id] = {
                    merchant_info: {} as TMerchanInfo,
                    vendor: item.vendor,
                    user_item_qty: selectedItem.user_item_qty,
                    item_packaging_qty:item.packaging[Object.keys(item.packaging)[0]] || 1,
                    item_packaging_value:Object.keys(item.packaging)[0] || 'each',
                    title: item.title,
                    id: item.id,
                    qty: item.qty || 0,
                    ordered_qty: item.ordered_qty || 0,
                    unit: item.unit,
                    price: item.price || 0
                };
            }
            return acc;
        }, {} as { [id: string]: SelectedItemInfo });

        setSelectedItemInfos(initialSelectedItemInfos);

        const parsedItemTypes = mergedItemTypes.reduce((acc, laborItem, index) => {
            const clone_id = `${laborItem.item_type.id}-${index}-${index}`;
            const item = initialSelectedItemInfos[clone_id];
            const title = laborItem.item_type.title;

            let qty = item ? item.qty : laborItem.qty;
            if (!qty) {
                qty = 0;
            }

            let orderedQty = item ? item.ordered_qty : laborItem.item_type.ordered_qty;
            if (!orderedQty) {
                orderedQty = 0;
            }

            const price = laborItem.item_type.items && laborItem.item_type.items.length > 0
                ? laborItem.item_type.items[0].price
                : 0;
            const priceTotalQty = item ? item.price * qty : 0;
            const isItemSelected = laborItem.item_type.items && laborItem.item_type.items.length > 0;
            const unit = laborItem.item_type.items && laborItem.item_type.items.length > 0
                ? laborItem.item_type.items[0].unit
                : '';
            const itemId = laborItem.item_type.id

            acc[index] = {
                index,
                title,
                qty,
                orderedQty,
                price,
                priceTotalQty,
                isItemSelected,
                unit,
                itemId
            };

            return acc;
        }, {} as { [index: number]: any });

        setParsedItemTypes(parsedItemTypes);
        calculateTotalPrice();
    }, [laborItemTypes]);

    useEffect(() => {
        calculateTotalPrice();
    }, [selectedItemInfos]);

    const calculateTotalPrice = () => {
        let totalPrice = 0;
        selectedItems.forEach((selectedItem) => {
            const item = selectedItemInfos[selectedItem.clone_id];
            if (item) {
                const generalPrice = item.price * (item.user_item_qty || item.qty);
                const difTotalPrice = Math.max(0, item.price * Math.min(item.ordered_qty, item.user_item_qty || item.qty));
                totalPrice += generalPrice - difTotalPrice;
            }
        });
        setTotalPrice(totalPrice);
    };

    const storeId = project?.store_id ? project.store_id : 0;


    const fetchItems = async (index: number, page: number = 1, searchTerm: string = '') => {
        setSelectedItemTypeIndex(index);
        setLoading(true);
        try {
            const url = searchTerm
                ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${laborItemTypes[index].item_type.id}?page=${page}&perPage=${itemsPerPage}&title=${searchTerm}&storeIdFromProjects=${storeId}`
                : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${laborItemTypes[index].item_type.id}?page=${page}&perPage=${itemsPerPage}&storeIdFromProjects=${storeId}`;

            const response = await get(url);
            setTotalItems(response.data.items.pagination.lastPage);

            const itemsWithQty = (Array.isArray(response.data.items.data) ? response.data.items.data : [response.data.items.data]).map(
                (item: TItem) => {
                    let qty_item_type = 0;

                    qty_item_type = laborItemTypes
                        .filter(labor => labor.item_type.id === laborItemTypes[index].item_type.id)
                        .reduce((totalQty, currentLabor) => totalQty + currentLabor.qty, 0);

                    return {
                        ...item,
                        qty_item_type: qty_item_type,
                    };
                }
            );
            setItems(itemsWithQty);
        } catch (error) {
            console.error("Failed to get elements:", error);
        } finally {
            setLoading(false);
        }
    };


    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, pageNumber, searchTerm);
        }
    };


    const handleButtonClick = (index: number) => {
        setIsModalOpen(true);
        fetchItems(index, currentPage);
    };

    const handleReload = () => {
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, currentPage);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleItemClick = (items: TItem[], itemTypeIndex: number | null) => {
        if (itemTypeIndex !== null) {
            const updatedSelectedItems = [
                ...selectedItems.filter(selectedItem => selectedItem.index !== itemTypeIndex),
                ...items.map((item, idx) => ({
                    itemTypeId: laborItemTypes[itemTypeIndex].item_type.id,
                    itemId: item.id,
                    index: itemTypeIndex,
                    item_packaging_qty:item.packaging[Object.keys(item.packaging)[0]] || 1,
                    item_packaging_value:Object.keys(item.packaging)[0] || 'each',
                    user_item_qty: item.user_item_qty,
                    clone_id: `${item.id}-${itemTypeIndex}-${idx}`,
                })),
            ];

            const updatedItemInfos = items.reduce((acc, item, idx) => {
                const clone_id = `${item.id}-${itemTypeIndex}-${idx}`;
                acc[clone_id] = {
                    merchant_info: {} as TMerchanInfo,
                    vendor: item.vendor,
                    title: item.title,
                    id: item.id,
                    qty: item.qty,
                    ordered_qty: item.ordered_qty || 0,
                    user_item_qty: item.user_item_qty || 0,
                    item_packaging_qty:item.packaging[Object.keys(item.packaging)[0]] || 1,
                    item_packaging_value:Object.keys(item.packaging)[0] || 'each',
                    unit: item.unit,
                    price: item.price || 0,
                };
                return acc;
            }, { ...selectedItemInfos });

            setSelectedItemInfos(updatedItemInfos);
            setSelectedItems(updatedSelectedItems);
            calculateTotalPrice();
        }
        setIsModalOpen(false);
    };

    const handleQtyChange = (clone_id: string, newQty: string) => {
        const parsedQty = parseInt(newQty);
        if (!isNaN(parsedQty)) {
            setDebouncedOrderedQty((prev) => ({
                ...prev,
                [clone_id]: newQty,
            }));
        } else {
            setDebouncedOrderedQty((prev) => ({
                ...prev,
                [clone_id]: '',
            }));
        }
        setSelectedItemInfos((prev) => ({
            ...prev,
            [clone_id]: {
                ...prev[clone_id],
                ordered_qty: parsedQty,
            },
        }));
        calculateTotalPrice();
    };

    const handleSave = async () => {
        function isMergedItemType(itemTypeId: number): boolean {
            return laborItemTypes.filter(laborItem => laborItem.item_type.id === itemTypeId).length > 1;
        }

        function generateIndexes(itemTypeId: number) {
            return laborItemTypes
                .filter(laborItem => laborItem.item_type.id === itemTypeId)
                .map(laborItem => ({
                    fragment_id: laborItem.fragment_id,
                    fragment_index: laborItem.fragment_index,
                    item_type_index: laborItem.item_type_index,
                    qty: laborItem.item_type.qty,
                }));
        }

        async function handleItemOrderedQty(updatedProject: Project, isCascade: boolean) {
            const newLaborItemTypes = filterLaborAndToolsItemTypes(updatedProject, templateIndex, 'labor');

            const filteredItemOrderedQtyBody = selectedItems
                .filter(selection => isMergedItemType(selection.itemTypeId) === isCascade)
                .map(selection => {
                    const userOrderedQtyStr = debouncedOrderedQty[selection.clone_id];
                    const userOrderedQty = userOrderedQtyStr !== undefined && userOrderedQtyStr !== '' ? parseInt(userOrderedQtyStr, 10) : undefined;
                    const initialInfo = selectedItemInfos[selection.clone_id];
                    const existingOrderedQty = initialInfo?.ordered_qty || 0;
                    const ordered_qty = userOrderedQty !== undefined ? userOrderedQty : existingOrderedQty;

                    return {
                        ordered_qty,
                        template_index: newLaborItemTypes.laborItemTypes?.[selection.index]?.template_index ?? 0,
                        item_type_index: newLaborItemTypes.laborItemTypes?.[selection.index]?.item_type_index ?? 0,
                        template_id: templateId,
                        item_type_id: selection.itemTypeId,
                        item_id: selection.itemId,
                        fragment_id: newLaborItemTypes.laborItemTypes?.[selection.index]?.fragment_id ?? 0,
                        fragment_index: newLaborItemTypes.laborItemTypes?.[selection.index]?.fragment_index ?? 0,
                        ...(isCascade && {indexes: generateIndexes(selection.itemTypeId)}),
                    };
                });
            if (filteredItemOrderedQtyBody.length > 0) {
                const url = isCascade
                    ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/item_ordered_qty_cascade`
                    : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/item_ordered_qty`;

                try {
                    await patch(url, { data: filteredItemOrderedQtyBody });
                } catch (error) {
                    console.error(`Failed to update ordered_qty ${isCascade ? 'cascade' : ''}:`, error);
                }
            }
        }

        const groupedItems = selectedItems.reduce((acc: { [key: string]: GroupedItem }, selection) => {
            const key = `${selection.itemTypeId}-${selection.index}`;
            if (!acc[key]) {
                acc[key] = {
                    item_type_id: selection.itemTypeId,
                    item_ids: [],
                    template_index: laborItemTypes[selection.index].template_index,
                    item_type_index: laborItemTypes[selection.index].item_type_index,
                    template_id: templateId,
                    fragment_id: laborItemTypes[selection.index].fragment_id,
                    fragment_index: laborItemTypes[selection.index].fragment_index,
                };
            }
            if (selection.itemId && selection.user_item_qty > 0) {
                acc[key].item_ids.push({ id: selection.itemId, qty: selection.user_item_qty * selection.item_packaging_qty });
            }
            return acc;
        }, {} as { [key: string]: GroupedItem });

        const ordinaryItems = Object.values(groupedItems).filter(item => !isMergedItemType(item.item_type_id));
        const mergedItems = Object.values(groupedItems).filter(item => isMergedItemType(item.item_type_id));
        const itemsChanged = selectedItems.some(selection => {
            const initialInfo = selectedItemInfos[selection.clone_id];
            return !initialInfo || selection.user_item_qty > 0;
        }) || Object.keys(selectedItemInfos).length !== selectedItems.length;

        const deletedItems = laborItemTypes.map((item, index) => ({
            item_type_id: item.item_type.id,
            item_ids: [],
            template_index: item.template_index,
            item_type_index: item.item_type_index,
            template_id: templateId,
            fragment_id: item.fragment_id,
            fragment_index: item.fragment_index,
        })).filter(item => !selectedItems.some(selection => selection.itemTypeId === item.item_type_id));

        let updatedProject: Project | undefined;

        try {
            if (itemsChanged || deletedItems.length > 0) {
                if (ordinaryItems.length > 0 || deletedItems.length > 0) {
                    const response = await patch(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/add_item`, {
                        data: [...ordinaryItems, ...deletedItems],
                    });
                    if (response.status === 200 || response.status === 201) {
                        updatedProject = response.data;
                        await handleItemOrderedQty(updatedProject!, false);
                    } else {
                        throw new Error("Failed to update project with regular elements.");
                    }
                }
                if (mergedItems.length > 0) {
                    const mergedItemsWithIndexes = mergedItems.map(item => ({
                        ...item,
                        indexes: generateIndexes(item.item_type_id),
                    }));

                    const responseCascade = await patch(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/add_item_cascade`, {
                        data: mergedItemsWithIndexes,
                    });

                    if (responseCascade.status === 200 || responseCascade.status === 201) {
                        updatedProject = responseCascade.data;
                        await handleItemOrderedQty(updatedProject!, true);
                    } else {
                        throw new Error("Failed to update project with merged elements.");
                    }
                }
            }
        } catch (error) {
            console.error("Failed to save selections:", error);
        }

        handleCloseModal();
        if (updatedProject) {
            onUpdateProject(updatedProject);
            onToggle();
        }
    };

    if (!laborItemTypes || !Array.isArray(laborItemTypes)) {
        return null;
    }

    const handleSearch = (query: string) => {
        setSearchTerm(query);
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, 1, query);
        }
    };

    const calculateTotalQty = (id: number) => {
        return laborItemTypes
            .filter(type => type.item_type.id === id)
            .reduce((total, current) => total + (current.qty || 0), 0);
    };

    return (
        <div>
            {laborItemTypes.length > 0 && (
                <>
                    {Object.values(parsedItemTypes).map((parsedItem, index) => {
                        if (!parsedItem) return null;
                        const {title, qty, price, itemId} = parsedItem;
                        const totalQty = calculateTotalQty(itemId);
                        const selectedItemsForType = selectedItems.filter(item => item.itemTypeId === laborItemTypes[parsedItem.index].item_type.id);
                        const itemTypeKey = generateItemTypeKey(parsedItem,index);

                        return (
                            <div key={`${laborItemTypes[parsedItem.index].item_type.id}-${index}`}>
                                    <label
                                        className="step2-item-type-label"
                                        htmlFor={`button-${laborItemTypes[parsedItem.index].item_type.id}-${index}`}
                                        style={{color: "#828282"}}
                                    >
                                        {title}
                                    </label>
                                <div>
                                    {selectedItemsForType.length === 0 ? (
                                        !isSmallScreen ? (
                                            <div className='step2-item-type-list'>
                                                <button
                                                    className="step2-item-button-list"
                                                    id={`button-${laborItemTypes[parsedItem.index].item_type.id}-${index}`}
                                                    onClick={() => handleButtonClick(parsedItem.index)}
                                                    disabled={loading}
                                                >
                                                    {loading && selectedItemTypeIndex === parsedItem.index
                                                        ? "Loading..."
                                                        : "Select Labors"}
                                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7 13L18 13M18 13L13.875 17M18 13L13.875 9"
                                                              stroke="#0053A0" strokeWidth="1.5" strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                        <circle cx="13" cy="13" r="12" stroke="#0053A0" strokeWidth="2"/>
                                                    </svg>
                                                </button>
                                                <p className="step2-other-width-list">{totalQty}<span>({parsedItem.unit ? parsedItem.unit : 'psc'})</span></p>
                                                <div className="step2-tooltip-container">
                                                    <input
                                                        type="number"
                                                        className="step2-other-width-input"
                                                        min="0"
                                                        disabled={!loading}
                                                    />
                                                </div>
                                                <p className="step2-other-width-list">R {parseInt(price).toFixed(2)}</p>
                                                <p className="step2-other-width-list">
                                                    R {parseInt((qty * price).toFixed(2))}
                                                </p>
                                                <p className="step2-other-width-list-total">{Math.max(0, qty * price).toFixed(2)}</p>
                                                <div className='step2-other-width-vendor-list'></div>
                                            </div>
                                        ): (
                                            <div className='step2-item-type-list'>

                                                <div className='step2-item-type-list-block-arrow'>
                                                    <button
                                                        className="step2-item-button-list"
                                                        id={`button-${laborItemTypes[parsedItem.index].item_type.id}-${index}`}
                                                        onClick={() => handleButtonClick(parsedItem.index)}
                                                        disabled={loading}
                                                    >
                                                        {loading && selectedItemTypeIndex === parsedItem.index
                                                            ? "Loading..."
                                                            : 'Select Labors'}
                                                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13 7L13 18M13 18L9 14M13 18L17 14"
                                                                  stroke="#0053A0" strokeWidth="1.5"
                                                                  strokeLinecap="round"
                                                                  strokeLinejoin="round"/>
                                                            <circle cx="13" cy="13" r="12" stroke="#0053A0"
                                                                    strokeWidth="2"/>
                                                        </svg>
                                                    </button>
                                                    <div onClick={() => toggleVisibility(itemTypeKey)}
                                                         className={isVisible(itemTypeKey) ? 'icon-arrow-light-step2-up-calc' : 'icon-arrow-light-step2-down-calc'}>
                                                        <IconArrowLightStpe2/>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`step2-content-list ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                    <div className="step2-tooltip-container">
                                                        <span>QTY to order:</span><span className="dashed-line"></span>
                                                        <input
                                                            type="number"
                                                            className="step2-other-width-input"
                                                            min="0"
                                                            disabled={!loading}
                                                        />
                                                    </div>
                                                    <p className="step2-other-width-list">
                                                        <span>QTY ordered:</span><span
                                                        className="dashed-line"></span>{totalQty}<span>({parsedItem.unit ? parsedItem.unit : 'psc'})</span>
                                                    </p>
                                                    <p className="step2-other-width-list">
                                                        <span>Unit price:</span><span
                                                        className="dashed-line"></span>R {parseInt(price).toFixed(2)}
                                                    </p>
                                                    <p className="step2-other-width-list">
                                                        <span>Total:</span><span className="dashed-line"></span>
                                                        R {parseInt((qty * price).toFixed(2))}
                                                    </p>
                                                    <p className="step2-other-width-list-total total-ordred-bold">
                                                        <span>Total ordered:</span><span
                                                        className="dashed-line"></span>R {Math.max(0, qty * price).toFixed(2)}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        selectedItemsForType.map((selectedItem, idx) => {
                                            const item = selectedItemInfos[selectedItem.clone_id];
                                            if (!item) return null;
                                            const totalPrice = item.price * (item.user_item_qty || item.qty);

                                            return (
                                                !isSmallScreen ? (
                                                    <div key={`${selectedItem.clone_id}`}
                                                         className='step2-item-type-list'>
                                                        <button
                                                            className="step2-item-button-list"
                                                            id={`button-${laborItemTypes[parsedItem.index].item_type.id}-${index}-${idx}`}
                                                            onClick={() => handleButtonClick(parsedItem.index)}
                                                            disabled={loading}
                                                        >
                                                            {loading && selectedItemTypeIndex === parsedItem.index
                                                                ? "Loading..."
                                                                : item.title}
                                                            <svg width="26" height="26" viewBox="0 0 26 26"
                                                                 fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7 13L18 13M18 13L13.875 17M18 13L13.875 9"
                                                                      stroke="#0053A0" strokeWidth="1.5"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                                <circle cx="13" cy="13" r="12" stroke="#0053A0"
                                                                        strokeWidth="2"/>
                                                            </svg>
                                                        </button>

                                                        <p className="step2-other-width-list">{item.user_item_qty || item.qty}<span>(psc)</span>
                                                        </p>
                                                        <div className="step2-tooltip-container">
                                                            <input
                                                                type="number"
                                                                className="step2-other-width-input"
                                                                value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                min="0"
                                                            />
                                                            <span
                                                                className='packing-value-steps'>psc</span>
                                                        </div>
                                                        <p className="step2-other-width-list">R {parseInt(String(item.price)).toFixed(2)}</p>
                                                        <p className="step2-other-width-list">
                                                            R {totalPrice.toFixed(2)}
                                                        </p>
                                                        <p className="step2-other-width-list-total">R {Math.max(0, totalPrice).toFixed(2)}</p>
                                                        <div className='step2-other-width-vendor-list'>
                                                            {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                            ) : (
                                                                <p className='item-selection-modal-no-vendor'>No
                                                                    Vendor</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div key={`${selectedItem.clone_id}`}
                                                         className='step2-item-type-list'>
                                                        <div className='step2-item-type-list-block-arrow'>
                                                            <button
                                                                className="step2-item-button-list"
                                                                id={`button-${laborItemTypes[parsedItem.index].item_type.id}-${index}-${idx}`}
                                                                onClick={() => handleButtonClick(parsedItem.index)}
                                                                disabled={loading}
                                                            >
                                                                {loading && selectedItemTypeIndex === parsedItem.index
                                                                    ? "Loading..."
                                                                    : item.title}
                                                                <svg width="26" height="26" viewBox="0 0 26 26"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M13 7L13 18M13 18L9 14M13 18L17 14"
                                                                          stroke="#0053A0" strokeWidth="1.5"
                                                                          strokeLinecap="round"
                                                                          strokeLinejoin="round"/>
                                                                    <circle cx="13" cy="13" r="12" stroke="#0053A0"
                                                                            strokeWidth="2"/>
                                                                </svg>
                                                            </button>
                                                            <div onClick={() => toggleVisibility(itemTypeKey)}
                                                                 className={isVisible(itemTypeKey) ? 'icon-arrow-light-step2-up-calc' : 'icon-arrow-light-step2-down-calc'}>
                                                                <IconArrowLightStpe2/>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`step2-content-list ${isVisible(itemTypeKey) ? 'visible' : 'hidden'}`}>
                                                            <div className="step2-tooltip-container">
                                                                <span>QTY to order:</span><span
                                                                className="dashed-line"></span>
                                                                <input
                                                                    type="number"
                                                                    className="step2-other-width-input"
                                                                    value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                    onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                    min="0"
                                                                />
                                                            </div>
                                                            <p className="step2-other-width-list">
                                                                <span>QTY ordered:</span><span
                                                                className="dashed-line"></span>{item.user_item_qty || item.qty}
                                                                <span>(psc)</span>
                                                            </p>
                                                            <p className="step2-other-width-list">
                                                                <span>Unit price:</span><span
                                                                className="dashed-line"></span>R {parseInt(String(item.price)).toFixed(2)}
                                                            </p>
                                                            <p className="step2-other-width-list">
                                                                <span>Total:</span><span
                                                                className="dashed-line"></span>
                                                                R {totalPrice.toFixed(2)}
                                                            </p>
                                                            <p className="step2-other-width-list-total total-ordred-bold">
                                                                <span>Total ordered:</span><span
                                                                className="dashed-line"></span>R {Math.max(0, totalPrice).toFixed(2)}
                                                            </p>
                                                            <div className='step2-other-width-vendor-list'>
                                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                    <p className='item-selection-modal-vendor'>{vendorData[item.vendor as Vendor].displayName}</p>
                                                                ) : (
                                                                    <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            );
                                        })
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
            <ItemSelectionModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                items={items}
                loading={loading}
                handleItemClick={(items) => handleItemClick(items, selectedItemTypeIndex)}
                selectedItems={selectedItems.filter(item => item.index === selectedItemTypeIndex)}
                onReload={handleReload}
                currentPage={currentPage}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
                onSearch={handleSearch}
                totalSearchResults={totalItems}
                storeId={storeId}
            />

            <div className='step2-bottom-block'>
                {!isSmallScreen ? (
                    <>
                        <p>Total:</p>
                        {totalPrice ? (
                            <span> R {totalPrice.toFixed(2)}</span>
                        ) : (
                            <span> R 0</span>
                        )}
                        <button className='step2-bottom-block-btn-cart-list' onClick={handleSave}>Save</button>
                    </>
                ) : (
                    <>
                        <div className='step2-bottom-block-mobile'>
                            <p>Total: </p>
                            <span className="dashed-line"></span>
                            {totalPrice ? (
                                <span className='mobile-total-price-red'> R {totalPrice.toFixed(2)}</span>
                            ) : (
                                <span className='mobile-total-price-red'> R 0</span>
                            )}
                        </div>
                        <button className='step2-bottom-block-btn-cart-list' onClick={handleSave}>Save</button>
                    </>
                )}
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    updateLocalTemplate,
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LaborItemTypeListCalculation);
