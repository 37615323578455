import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import './UploadModal.css';
import { IconArhitectorCreateProject, IconLoadedFile } from "../../../../IconComponents/IconComponents";
import ProgressBarFragment from "./ProgressBarLoading";

interface UploadModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
}
const BASE_URL = process.env.REACT_APP_BASE_URL_ARCHITECTOR;

const UploadModal: React.FC<UploadModalProps> = ({ isOpen, onRequestClose }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [loadedBytes, setLoadedBytes] = useState<number>(0);
    const [uploadPercentage, setUploadPercentage] = useState<number>(0);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [uploadSuccess, setUploadSuccess] = useState<boolean | null>(null);
    const [urn, setUrn] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string>('');
    const inputRef = useRef<HTMLInputElement | null>(null);
    const navigate = useNavigate();
    const xhrRef = useRef<XMLHttpRequest | null>(null);

    const resetStates = () => {
        setSelectedFile(null);
        setLoadedBytes(0);
        setUploadPercentage(0);
        setIsUploading(false);
        setUploadSuccess(null);
        setUrn(null);
        if (xhrRef.current) {
            xhrRef.current.abort();
            xhrRef.current = null;
        }
    };


    const handleFiles = async (files: FileList) => {
        if (files.length > 0) {
            const file = files[0];
            setSelectedFile(file);
            setLoadedBytes(0);
            setUploadPercentage(0);
            setIsUploading(true);
            setUploadSuccess(null);

            try {
                const urn = await uploadFileWithProgress(file);
                setUploadSuccess(true);
                setUrn(urn);
            } catch (error) {
                console.error("File upload failed:", error);
                setUploadSuccess(false);
            } finally {
                setIsUploading(false);
            }
        }
    };

    const uploadFileWithProgress = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhrRef.current = xhr;
            const url = `${BASE_URL}/revit/pars_project?name=${encodeURIComponent(file.name)}&size=${file.size}`;
            setFileName(file.name);
            xhr.open("POST", url);

            const rememberMe = localStorage.getItem('rememberMe') === 'true';
            const bearerToken = rememberMe
                ? localStorage.getItem('Bearer')
                : sessionStorage.getItem('Bearer');

            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', `Bearer ${bearerToken}`);
            xhr.setRequestHeader('Access-Control-Allow-Origin', `${BASE_URL}`);
            xhr.setRequestHeader('Content-Type', 'application/octet-stream');

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percent = Math.round((event.loaded / event.total) * 100);
                    setLoadedBytes(event.loaded);
                    setUploadPercentage(percent);
                }
            };

            xhr.onload = () => {
                xhrRef.current = null;
                if (xhr.status === 200) {
                    const response = JSON.parse(xhr.responseText);
                    const { urn, token } = response;

                    if (token) {
                        localStorage.setItem('autodeskToken', token);
                    }
                    if (urn) {
                        resolve(urn);
                    } else {
                        reject(new Error("URN not found in response."));
                    }
                } else {
                    reject(new Error(`Download status: ${xhr.status}`));
                }
            };

            xhr.onerror = () => {
                xhrRef.current = null;
                reject(new Error("An error occurred while uploading a file."));
            };

            xhr.onabort = () => {
                xhrRef.current = null;
                reject(new Error("The file upload has been canceled."));
            };
            xhr.send(file);
        });
    };

    const handleNextStep = () => {
        if (urn) {
            navigate(`/architect-page/${encodeURIComponent(urn)}`, { state: { urn: urn, fileName:fileName } });
        } else {
            console.error("URN is not available. Cannot navigate to the next step.");
        }
    };



    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        handleFiles(files);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            handleFiles(e.target.files);
        }
    };

    const handleCloseModal = () => {
        resetStates();
        onRequestClose();
    };

    const formatSize = (size: number): string => {
        if (size < 1024) return `${size} bytes`;
        else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
        else return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCloseModal}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            className= {`arhitector-upload-modal ${(uploadPercentage === 100 && isUploading) && 'revit-loading'}`}
            overlayClassName="overlay-arhitector-upload"
        >
            <div>
                <button className="close-button" onClick={handleCloseModal}>&times;</button>
            </div>
            {!isUploading && !selectedFile && uploadPercentage !== 100 && (
                <>
                    <div
                        className='arhitector-upload-block'
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onClick={handleClick}
                    >
                        <div><IconArhitectorCreateProject /></div>
                        <p className='arhitector-upload-block-text'>Drag files to upload</p>
                        <input
                            type="file"
                            ref={inputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                    </div>
                    <button className='arhitector-upload-button' onClick={handleClick}>Select in files</button>
                </>
            )}

            {isUploading && selectedFile && uploadPercentage !== 100 && (
                <>
                    <div className='arhitector-upload-block'>
                        <div className='upload-progress'>
                            <div className='progress-bar'>
                                <ProgressBarFragment totalLoading={uploadPercentage}/>
                            </div>
                            <div className='arhitector-upload-progress-text'>
                                <p>{formatSize(loadedBytes)} / {formatSize(selectedFile.size)}</p>
                                <p>Uploading... {uploadPercentage}%</p>
                            </div>
                        </div>
                    </div>
                    <button
                        className="arhitector-upload-button"
                        onClick={resetStates}
                    >
                        Done
                    </button>
                </>
            )}

            {uploadPercentage === 100 && isUploading && (
                <div className='arhitector-revit-loading'>
                    <div className='loading-circle-revit'>
                        <p className="loading-revit-text">
                            File uploaded!<br/>
                            Processing is taking place on the server.<br/>
                            Please wait a few minutes.
                        </p>
                    </div>
                </div>
            )}

            {!isUploading && selectedFile && uploadSuccess && (
                <>
                    <div className='arhitector-upload-block'>
                        <div><IconLoadedFile/></div>
                        <p className='arhitector-upload-block-text'>The Revit file was successfully uploaded.</p>
                    </div>
                    <button className='arhitector-upload-button' onClick={handleNextStep}>Next step</button>
                </>
            )}

            {!isUploading && selectedFile && uploadSuccess === false && (
                <>
                    <div className='arhitector-upload-block'>
                        <p className='arhitector-upload-block-text'>Failed to upload the file. Please try again.</p>
                    </div>
                    <button className='arhitector-upload-button' onClick={handleCloseModal}>Done</button>
                </>
            )}
        </Modal>
    );
};

export default UploadModal;
