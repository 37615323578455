import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchTemplateById } from "../../Slice/getByIdTemplate/ByIdTemplateSlice";
import { RootState } from "../../store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import './style.css';
import { Loader } from "../../components/Loader/Loader";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import { Project } from "../../types/ProjectTypes";
import { addTemplate } from "../../Slice/LocalTemplate/LocalTemplateSlice";
import CreateProjectModal from "../../components/Modals/ModalCreateProject/ModalCreateProject";
import ModalAddTemplate from "../../components/ProjectAssistanceTemplatesAdd/ModalAddTemplate";
import { IconTemplatesAssistance } from "../../IconComponents/IconComponents";
import { get } from "../../api/APIWraper";

const TEMPLATE_URL = process.env.REACT_APP_BASE_URL_CUSTOMER;

const TemplatePage: React.FC<PropsFromRedux> = ({ template, isFetching, fetchTemplateById, isAuthenticated,addTemplate }) => {
    const { tempId } = useParams<{ tempId: string }>();
    const navigate = useNavigate();
    const isFirstRender = useRef(true);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isModalOpenProject, setIsModalOpenProject] = useState<boolean>(false);
    const [selectedTempId, setSelectedTempId] = useState<number>(0);
    const [selectedTempTitle, setSelectedTempTitle] = useState<string>('');
    const [htmlContent, setHtmlContent] = useState<string | null>(null);
    const [loadingDescription, setLoadingDescription] = useState<boolean>(true);
    const dispatch = useDispatch();

    const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);

    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        return () => {
            setIsModalOpen(false);
            setIsModalOpenProject(false);
            setSelectedTempId(0);
            setSelectedTempTitle('');
            setHtmlContent(null);
            setLoadingDescription(true);
            setIsSmallScreen(window.innerWidth <= 920);
        };
    }, []);


    useEffect(() => {
        if (template) {
            if (!template.description) {
                setIsLoadingPage(false);
            } else {
                const fetchHtmlContent = async () => {
                    try {
                        const response = await get(`${TEMPLATE_URL}/templates/description?path=${template.description}`);
                        setHtmlContent(response.data.description);
                    } catch (error) {
                        console.error('Error fetching HTML content:', error);
                    } finally {
                        setLoadingDescription(false);
                    }
                };
                fetchHtmlContent();
            }
        }
    }, [template]);

    useEffect(() => {
        if (isAuthenticated) {
            if (isFirstRender.current && tempId) {
                setIsLoadingPage(true);

                fetchTemplateById({
                    templateId: parseInt(tempId),
                    queryParams: '?with[]=title&with[]=description&with[]=reviews'
                });

                isFirstRender.current = false;
            }
        } else {
            navigate('/auth/login');
        }
    }, [fetchTemplateById, tempId, isAuthenticated]);

    useEffect(() => {
        if (!isFetching && !loadingDescription && template) {
            setIsLoadingPage(false);
        }
    }, [isFetching, loadingDescription, template]);

    const handleOpenModal = () => setIsModalOpenProject(true);

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setIsModalOpenProject(false);
    };

    const infoTemplateToModal = () => {
        setIsModalOpen(true);
        if (tempId != null) {
            setSelectedTempId(parseInt(tempId));
        }
        if (template) {
            setSelectedTempTitle(template.title);
        }
    };

    const handleProjectCreation = (project: Project) => {
        if (template) dispatch(addTemplate(template));
        navigate(`/assistance/create/${project.id}/from/projectCosts`, { state: { projectId: project.id } });
    };

    if (isLoadingPage) {
        return (<Loader />);
    }

    const renderCategories = () => {
        if (template) {
            if (template.categories.length === 0) return null;
            return (
                <div className='blog-categories'>
                    <p>Category: {template.categories.map(category => category.category).join(', ')}</p>
                    <IconTemplatesAssistance />
                </div>
            );
        }
        return null;
    };

    const renderMediaSections = () => {
        if (!htmlContent) {
            return <p>No content available</p>;
        }

        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(htmlContent, "text/html");
        const blocks = parsedHtml.querySelectorAll(".image-block");

        if (blocks.length === 0) {
            return (
                <div className="blog-section">
                    <div className="blog-media-section full-width-text blog-text-block-only">
                        <div className="blog-text-block-only">
                            <p
                                className="blog-text-block-p-only"
                                style={{ fontFamily: 'Roboto, sans-serif', fontSize: '20px' }}
                                dangerouslySetInnerHTML={{ __html: htmlContent }}
                            ></p>
                        </div>
                    </div>
                </div>
            );
        }

        return Array.from(blocks).map((block, index) => {
            const isOdd = index % 2 !== 0;
            const image = block.querySelector("img");
            const text = block.querySelector(".image-text");

            const hasImage = !!image;
            const hasText = !!(text?.innerHTML && text.innerHTML.trim());

            let mediaClass = '';
            if (hasImage && !hasText) {
                mediaClass = 'full-width-image';
            } else if (!hasImage && hasText) {
                mediaClass = 'full-width-text blog-text-block-only';
            } else if (hasImage && hasText) {
                mediaClass = isOdd ? 'odd' : 'even';
            }

            const textBlockClass = hasImage
                ? (isOdd ? 'block-even' : 'block-odd')
                : 'blog-text-block-only';

            const pClassName = hasImage
                ? 'blog-text-block-p'
                : 'blog-text-block-p blog-text-block-p-only';

            return (
                <div className='blog-section' key={index}>
                    <div className={`blog-media-section ${mediaClass}`}>
                        {hasImage && (
                            <img
                                className='blog-media-img'
                                src={image.src}
                                alt="Block Image"
                            />
                        )}
                        {hasText && (
                            <div className={`blog-text-block ${textBlockClass}`}>
                                <p
                                    className={pClassName}
                                    dangerouslySetInnerHTML={{ __html: text.innerHTML || 'No text' }}
                                    style={{ fontFamily: 'Roboto, sans-serif', fontSize: '20px' }}
                                ></p>
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    };

    const addTemplateButton = (containerClassName: string) => (
        <div className='blog-media-section-button-block'>
            <div className={`${containerClassName}`}>
                <button onClick={infoTemplateToModal} className='blog-media-section-btn-exis'>Add to existing project
                    group
                </button>
                <button onClick={handleOpenModal} className='blog-media-section-btn-new'>Add to new project space
                </button>
            </div>
        </div>
    );

    return (
        <div className='wrapper'>
            {isSmallScreen ? (
                <MobileNavigation
                    links={[
                        { to: '/', label: 'Cashbuild' },
                        { to: '', label: '>' },
                        { to: '/', label: 'Home' },
                        { to: '', label: '>' },
                        { to: '/assistance', label: 'Project assistance' },
                        { to: '', label: '>' },
                        { to: '/', label: 'Project page' }
                    ]}
                />
            ) : (
                <div className='template-blog-placeholder'>
                    <p>Home/</p>
                    <p onClick={() => navigate('/assistance')} className='template-link-assistance'>Project group assistance/</p>
                    <p className='name-link'>Project page</p>
                </div>
            )}

            <div className='blog-name'>
                <p className='blog-name-title'>{template?.title}</p>
                {renderCategories()}
            </div>

            {renderMediaSections()}
            {addTemplateButton("blog-media-section-button")}

            <ModalAddTemplate
                isOpen={isModalOpen}
                tempId={selectedTempId}
                tempTitle={selectedTempTitle}
                onClose={handleCloseModal}
            />
            <CreateProjectModal
                isOpen={isModalOpenProject}
                onRequestClose={handleCloseModal}
                template={template}
                onProjectCreated={handleProjectCreation}
            />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    template: state.templateById.template,
    error: state.templateById.error,
    isFetching: state.templateById.isFetching,
    isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = {
    fetchTemplateById,
    addTemplate
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(TemplatePage);
