import React, { useEffect, useRef, useState } from 'react';
import '../ArhitectStyles/RevitComponent.css';
import { fetchRevitToken } from "../../../api/APIWraper";

interface RevitComponentProps {
    urn: string;
    showWizard: boolean;
    currentIndex: number;
    setTotalObjects: (count: number) => void;
    updateExternalIds: (externalIds: string[]) => void;
    setExternalIdToDbId: React.Dispatch<React.SetStateAction<Record<string, number>>>;
}

const RevitComponent: React.FC<RevitComponentProps> = ({
                                                           urn,
                                                           showWizard,
                                                           currentIndex,
                                                           setTotalObjects,
                                                           updateExternalIds,
                                                           setExternalIdToDbId
                                                       }) => {
    const viewerContainer = useRef<HTMLDivElement | null>(null);
    const viewerRef = useRef<Autodesk.Viewing.GuiViewer3D | null>(null);

    const [externalIds, setExternalIds] = useState<string[]>([]);
    const [readyToShow, setReadyToShow] = useState(false);
    const [allDbIds, setAllDbIds] = useState<number[]>([]);

    const [isViewerReady, setIsViewerReady] = useState(false);

    const tokenRefreshTimer = useRef<NodeJS.Timeout | null>(null);

    const getAllLeafNodes = (tree: any, rootId: number): number[] => {
        const allLeafs: number[] = [];
        function recurse(dbId: number) {
            let childCount = 0;
            tree.enumNodeChildren(dbId, (childId: number) => {
                childCount++;
                recurse(childId);
            });
            if (childCount === 0) {
                allLeafs.push(dbId);
            }
        }
        recurse(rootId);
        return allLeafs;
    };

    const getRevitToken = async (): Promise<string> => {
        try {
            const response = await fetchRevitToken();
            if (response.status !== 200) {
                throw new Error(`Failed to refresh token. Status: ${response.status}`);
            }
            const newToken = response.data.token;
            localStorage.setItem('autodeskToken', newToken);

            if (tokenRefreshTimer.current) clearTimeout(tokenRefreshTimer.current);
            tokenRefreshTimer.current = setTimeout(() => {
                getRevitToken();
            }, 55 * 60 * 1000);

            return newToken;
        } catch (error) {
            console.error('Error fetching new token:', error);
            throw error;
        }
    };

    const initializeViewer = async (retry = false) => {
        if (typeof Autodesk === 'undefined') {
            console.error('Autodesk Viewer SDK not loaded');
            return;
        }

        let token = localStorage.getItem('autodeskToken');
        if (!urn || !token) {
            try {
                token = await getRevitToken();
            } catch (error) {
                console.error('Failed to get token:', error);
                return;
            }
        }

        const options = {
            env: 'AutodeskProduction',
            accessToken: token,
        };

        Autodesk.Viewing.Initializer(options, () => {
            if (!viewerRef.current) {
                viewerRef.current = new Autodesk.Viewing.GuiViewer3D(viewerContainer.current as HTMLElement);
                viewerRef.current.start();
            }

            const viewer = viewerRef.current;
            const documentId = `urn:${urn}`;

            Autodesk.Viewing.Document.load(
                documentId,
                (doc) => {
                    const viewables = doc.getRoot().getDefaultGeometry();
                    viewer.loadDocumentNode(doc, viewables);

                    viewer.addEventListener(
                        Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
                        () => {
                            viewer.getObjectTree((tree: any) => {
                                if (!tree) {
                                    console.error("Object tree not found");
                                    return;
                                }

                                const leafDbIds = getAllLeafNodes(tree, tree.getRootId());
                                setAllDbIds(leafDbIds);
                                setTotalObjects(leafDbIds.length);

                                const promises: Promise<void>[] = [];
                                tree.enumNodeChildren(
                                    tree.getRootId(),
                                    (dbId: number) => {
                                        const p = new Promise<void>((resolve) => {
                                            viewer.getProperties(
                                                dbId,
                                                (props: any) => {
                                                    if (props?.externalId) {
                                                        setExternalIdToDbId((prev) => ({
                                                            ...prev,
                                                            [props.externalId]: dbId,
                                                        }));
                                                        setExternalIds((prevIds) => [...prevIds, props.externalId]);
                                                    }
                                                    resolve();
                                                },
                                                (err: any) => {
                                                    console.error(`Error getting properties for dbId ${dbId}:`, err);
                                                    resolve();
                                                }
                                            );
                                        });
                                        promises.push(p);
                                    },
                                    true
                                );

                                Promise.all(promises).then(() => {
                                    setIsViewerReady(true);
                                });
                            });
                        }
                    );
                },
                async (err) => {
                    console.error('Error loading document:', err);
                    if (err === 4 && !retry) {
                        try {
                            token = await getRevitToken();
                            initializeViewer(true);
                        } catch (tokenError) {
                            console.error('Failed to refresh token:', tokenError);
                        }
                    } else {
                        console.error('Document load failed:', err);
                    }
                }
            );
        });
    };

    useEffect(() => {
        updateExternalIds(externalIds);
    }, [externalIds, updateExternalIds]);

    useEffect(() => {
        initializeViewer();
        return () => {
            if (viewerRef.current) {
                viewerRef.current.tearDown();
                viewerRef.current.finish();
                viewerRef.current = null;
            }
            if (tokenRefreshTimer.current) {
                clearTimeout(tokenRefreshTimer.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (viewerRef.current) {
            const timeout = setTimeout(() => {
                viewerRef.current?.resize();
                viewerRef.current?.fitToView();
            }, 500);

            return () => clearTimeout(timeout);
        }
    }, [showWizard]);

    useEffect(() => {
        if (showWizard) {
            const timeout = setTimeout(() => setReadyToShow(true), 1000);
            return () => clearTimeout(timeout);
        } else {
            setReadyToShow(false);
        }
    }, [showWizard]);

    useEffect(() => {
        const viewer = viewerRef.current;
        if (!viewer || !readyToShow || !isViewerReady) return;

        if (allDbIds.length > 0) {
            viewer.hideAll();
            const currentDbId = allDbIds[currentIndex];
            if (currentDbId !== undefined) {
                viewer.show([currentDbId]);
                viewer.fitToView([currentDbId]);
            }
        } else {
            setTimeout(() => {
                try {
                    if (viewerRef.current && viewer.showAll) {
                        viewer.showAll();
                    } else {
                        console.warn("viewer.showAll is not available yet");
                    }
                } catch (err) {
                    console.error("Error calling viewer.showAll()", err);
                }
            }, 1500);
        }
    }, [isViewerReady, readyToShow, currentIndex, allDbIds]);

    return (
        <div
            id={`forgeViewer${!showWizard ? '-wizard' : ''}`}
            ref={viewerContainer}
            className={`revit-container ${!showWizard ? 'wizard' : ''}`}
        />
    );
};

export default RevitComponent;
