import React from "react";
import './ProgressBarLoading.css';
import {IconLoadFile} from "../../../../IconComponents/IconComponents";

interface ProgressBarProps {
    totalLoading:number;
}

const ProgressBarFragment: React.FC<ProgressBarProps> = ({ totalLoading }) => {

    return (
        <div className="arhitector-upload-progress-bar ">
            <IconLoadFile/>
            <div className='arhitector-upload-progress-bar-container'>
                <div className='arhitector-upload-container-progressBarFill' style={{width: `${totalLoading}%`}}></div>
            </div>
        </div>
    );
}

export default ProgressBarFragment;
