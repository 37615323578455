import React, {useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {User} from "../../types/ProjectTypes";
import {RootState} from '../../store';

import {
    IconArrowDownOrderHistory,
    IconArrowUpOrderHistory,
    IconInfoIcon,
    IconRemove,
    IconSettings,
    IconUserDefaultProfile
} from "../../IconComponents/IconComponents";
import {RoleEditor} from "../../components/ProjectEditComponents/UserList";
import {PermissionEdit} from "../../components/RoleComponent/EnumRole";
import GetPermission from "../../components/RoleComponent/Role";
import ModalDelete from "../../components/Modals/ModalDelete/ModalDelete";
import EditPermissionsModal from "../../components/Modals/ModalEditUser/EditModalUser";

import {updatePermissionsArchitect} from "../../api/APIWraper";
import {toast} from "react-toastify";
import {ArchitectProject} from "../../types/ArhitectProject";

interface IUserList {
    isSmallScreen: boolean;
    setProject: (project: ArchitectProject) => void;
    project:ArchitectProject
}

const ArchitectUserList: React.FC<IUserList > = ({
                                                                     isSmallScreen,
                                                                     project,
                                                                     setProject,
                                                                 }) => {
    const [userToDelete, setUserToDelete] = useState<User | null>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditPermissionsModalOpen, setIsEditPermissionsModalOpen] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [currentRole, setCurrentRole] = useState<string>('User');
    const [isEditSection, setIsEditSection] = useState(true);
    const [isPurchasePermissions, setIsPurchasePermissions] = useState(true);
    const [isChangeAddress, setIsChangeAddress] = useState(true);
    const [isMakePayments, setIsMakePayments] = useState(true);
    const [roleEditor, setRoleEditor] = useState<RoleEditor>('Editor');
    const [isOpenEditor, setIsOpenEditor] = useState<boolean>(false);
    const [visibleUserIds, setVisibleUserIds] = useState<Set<number | string>>(new Set());

    if (!project) {
        return null;
    }

    const permissionsUsers = project.permissions.map(permission => permission.user);

    const uniqueUsers = Array.from(new Set(permissionsUsers.map(user => user?.id)))
        .map(id => permissionsUsers.find(user => user?.id === id) as User)
        .filter(user => user !== undefined);

    const handleDeleteClick = (user: User) => {
        setUserToDelete(user);
        setIsDeleteModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        if (userToDelete) {
            const body = {
                email: userToDelete.email,
                permissions: []
            };
            const response = await updatePermissionsArchitect(String(project.id),body)
            if(response.status === 200) {
                setProject(response.data);
            } else{
                toast.error(`Failed to update project. ${response.error}`)
            }
            setIsDeleteModalOpen(false);
            setUserToDelete(null);
        }
    };

    const handleEditPermissionsClick = (user: User, permissions?: []) => {
        setCurrentUser(user);
        const parsedPermissions = permissions?.filter((permission: any) => permission !== null) as PermissionEdit[];
        const userRole = parsedPermissions.includes(PermissionEdit.OWNER) ? 'Admin' : 'User';
        setCurrentRole(userRole);
        setIsEditPermissionsModalOpen(true);
    };

    const handleSavePermissions = async (email: string, role: string) => {
        if (project && currentUser) {
            const permissions = role === 'User'
                ? [PermissionEdit.READ]
                : [PermissionEdit.READ, PermissionEdit.UPDATE, PermissionEdit.DELETE, PermissionEdit.OWNER];

            const body = {
                projectId: String(project.id),
                email: email,
                permissions: permissions.map(permission => PermissionEdit[permission])
            };

            const response = await updatePermissionsArchitect(String(project.id),body)

            if(response.status === 200) {
                setProject(response.data);
            } else{
                toast.error(`Failed to update project. ${response.error}`)
            }
        }
        setIsEditPermissionsModalOpen(false);
    };

    const handleToggle = (essence: string) => {
        switch (essence) {
            case 'ItemSection': {
                setIsEditSection(!isEditSection);
                break;
            }
            case 'PurchasePermissions': {
                setIsPurchasePermissions(!isPurchasePermissions);
                break;
            }
            case 'ChangeAddress': {
                setIsChangeAddress(!isChangeAddress);
                break;
            }
            case 'MakePayments': {
                setIsMakePayments(!isMakePayments);
                break;
            }
        }
    };

    const toggleUserPermissions = (userId: number | string) => {
        setVisibleUserIds(prev => {
            const newSet = new Set(prev);
            if (newSet.has(userId)) {
                newSet.delete(userId);
            } else {
                newSet.add(userId);
            }
            return newSet;
        });
    };

    const toggleDropdownEditor = () => {
        setIsOpenEditor(!isOpenEditor);
    };

    const handleSelectEditor = (selectedRole: RoleEditor) => {
        setRoleEditor(selectedRole);
        setIsOpenEditor(false);
    };


    return (
        <div className='wrapper'>
            <ul className="table-content-data-section-container-users">
                {uniqueUsers.map(user => {

                    const userPermissions = project.permissions.find(permission => permission.user_id === user.id);
                    const parsedPermissions = userPermissions ? userPermissions.level.filter((permission: any) => permission !== null) as PermissionEdit[] : [];
                    return (
                        <li key={user.id}>
                            {!isSmallScreen ? (
                                <section className='table-content-data-section-user-block'>
                                    <div
                                        className="table-content-data-section-users">
                                        <div className="table-content-data-section-field-image-users">
                                            <div className='table-content-data-section-field-image-block'>
                                                {user.galleries && user.galleries.length > 0 ? (
                                                    <img
                                                        className="gallery-image-users icon-circle "
                                                        src={user.galleries[0].medias[user.galleries[0].medias.length - 1].url}
                                                        alt="User"
                                                    />
                                                ) : (
                                                    <div className='gallery-image-users icon-circle'>
                                                        <IconUserDefaultProfile/>
                                                    </div>
                                                )}
                                                <p className="table-content-data-section-field-image-title-users">
                                                    {user.username}
                                                </p>
                                            </div>
                                        </div>
                                        <p className="project-table-content-data-section-field-center-users-email">
                                            {user.email}
                                        </p>
                                        <p className='project-table-content-data-section-field-center-users-address'>
                                            {project?.address?.full_address || 'No address'}
                                        </p>
                                        <p className="project-table-content-data-section-field-center-users-count">
                                            {user.projects_count}
                                        </p>
                                        <div className="user-permissions-dropdown-editor">
                                            <button className="user-permissions-dropdown-button"
                                                    onClick={toggleDropdownEditor}>
                                                {roleEditor}
                                                <span
                                                    className={`user-permissions-arrow ${isOpenEditor ? 'user-permissions-open' : ''}`}/>
                                            </button>
                                            {isOpenEditor && (
                                                <div className="user-permissions-dropdown-content">
                                                    {['Editor', 'Viewer'].map((option) => (
                                                        <div
                                                            key={option}
                                                            onClick={() => handleSelectEditor(option as RoleEditor)}
                                                            className="user-permissions-dropdown-option"
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <div className="table-content-data-section-field-actions-2btn-users">
                                            <button
                                                onClick={() => handleEditPermissionsClick(user, userPermissions?.level)}
                                                className='costs-action-btn'>
                                                <div title='Permission settings' className='setting-users'>
                                                    <IconSettings/></div>
                                            </button>
                                            <button onClick={() => handleDeleteClick(user)}
                                                    className='costs-btn-costs'>
                                                <div title='Remove user' className='delete-users'><IconRemove/></div>
                                            </button>

                                            <div className='user-permissions-toggle'
                                                 onClick={() => toggleUserPermissions(user.id)}
                                                 title='Checking and editing the permissions'
                                            >
                                                {visibleUserIds.has(user.id) ? <IconArrowUpOrderHistory/> :
                                                    <IconArrowDownOrderHistory/>}
                                            </div>

                                        </div>
                                    </div>
                                    <div
                                        className={`user-permissions-container ${
                                            visibleUserIds.has(user.id) ? '' : 'user-permissions-hidden'
                                        }`}
                                    >
                                        <div className='user-permissions-header'>
                                            <p className='user-permissions-header-p'>Permissions</p>
                                        </div>
                                        <div className='user-permissions-content'>

                                            <div className='user-permissions-content-block'>
                                                <p>Edit Item Section</p>
                                                <div
                                                    className={`user-permissions-switcher-toggle ${isEditSection ? '' : 'active'}`}
                                                    onClick={() => handleToggle('ItemSection')}>
                                                    <div
                                                        className={`user-permissions-switcher-toggle-knob ${isEditSection ? '' : 'active'}`}></div>
                                                </div>
                                            </div>


                                            <div className='user-permissions-content-block'>
                                                <p>Purchase Permissions</p>
                                                <div
                                                    className={`user-permissions-switcher-toggle ${isPurchasePermissions ? '' : 'active'}`}
                                                    onClick={() => handleToggle('PurchasePermissions')}>
                                                    <div
                                                        className={`user-permissions-switcher-toggle-knob ${isPurchasePermissions ? '' : 'active'}`}></div>
                                                </div>
                                            </div>

                                            <div className='user-permissions-content-block-info'>
                                                <p>Add or Change Delivery Address</p>
                                                <div className="user-permissions-tooltip-container">
                                                    <IconInfoIcon/>
                                                    <span className="user-permissions-tooltip-text">Gives this user access to make payments on this project.</span>
                                                </div>
                                                <div
                                                    className={`user-permissions-switcher-toggle ${isChangeAddress ? '' : 'active'}`}
                                                    onClick={() => handleToggle('ChangeAddress')}>
                                                    <div
                                                        className={`user-permissions-switcher-toggle-knob ${isChangeAddress ? '' : 'active'}`}></div>
                                                </div>
                                            </div>

                                            <div className='user-permissions-content-block-info'>
                                                <p>Make Payments</p>
                                                <div className="user-permissions-tooltip-container">
                                                    <IconInfoIcon/>
                                                    <span className="user-permissions-tooltip-text">Gives this user access to make payments on this project.</span>
                                                </div>
                                                <div
                                                    className={`user-permissions-switcher-toggle ${isMakePayments ? '' : 'active'}`}
                                                    onClick={() => handleToggle('MakePayments')}>
                                                    <div
                                                        className={`user-permissions-switcher-toggle-knob ${isMakePayments ? '' : 'active'}`}>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </section>
                            ) : (
                                <section className="table-content-data-section-team small-screen">
                                    <>
                                        <div className="user-info-block">
                                            <div className="user-image-block">
                                                {user.galleries && user.galleries.length > 0 ? (
                                                    <img
                                                        className="width-table-img-team icon-circle"
                                                        src={user.galleries[user.galleries.length - 1].medias[user.galleries[user.galleries.length - 1].medias.length - 1].url}
                                                        alt="User"
                                                    />
                                                ) : (
                                                    <div className='width-table-img-team icon-circle'>
                                                        <IconUserDefaultProfile/>
                                                    </div>
                                                )}

                                            </div>
                                            <div className="user-info-details">
                                                <p>{userPermissions ? GetPermission(parsedPermissions) : 'No permissions'}</p>
                                                <p>{user.email}</p>
                                                <p>Projects: {user.projects_count}</p>
                                                <p className='project-table-content-data-section-field-center-users-address-mobile'>{project?.address?.full_address || 'No address'}</p>
                                            </div>
                                        </div>
                                        <div className="users-actions-btn-mobile">
                                            <button
                                                onClick={() => handleEditPermissionsClick(user, userPermissions?.level)}
                                                className='users-action-btn'>
                                                <div className='setting-users-mobile'><IconSettings/></div>
                                            </button>
                                            <button onClick={() => handleDeleteClick(user)}
                                                    className='costs-btn-users'>
                                                <div className='delete-users-mobile'><IconRemove/></div>
                                            </button>
                                        </div>
                                    </>
                                </section>
                            )}
                        </li>
                    );
                })}
            </ul>

            {isDeleteModalOpen && userToDelete && (
                <ModalDelete
                    isOpen={isDeleteModalOpen}
                    onRequestClose={() => setIsDeleteModalOpen(false)}
                    onCancel={() => setIsDeleteModalOpen(false)}
                    onConfirm={handleConfirmDelete}
                    message={`Are you sure you want to delete ${userToDelete.first_name || 'this user'}?`}
                    confirmText="Yes"
                    cancelText="No"
                />
            )}
            <EditPermissionsModal
                isOpen={isEditPermissionsModalOpen}
                onRequestClose={() => setIsEditPermissionsModalOpen(false)}
                onSave={handleSavePermissions}
                currentEmail={currentUser?.email || ''}
                currentRole={currentRole}
            />
        </div>
    );
};

export default ArchitectUserList;
