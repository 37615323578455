import React, {useEffect, useRef, useState} from "react";
import "../ArhitectStyles/ArhitectComponents.css";
import {
    IconArrowDownArhitect,
    IconArrowUpArhitect,
    IconRemoveBacketArhitect,
    IconSettingArhitect
} from "../../../IconComponents/IconComponents";
import {AllFragments} from "../ArсhitectPage";
import {ArchitectProject} from "../../../types/ArhitectProject";
import {removeCustomFragment, removeExistFragment} from "../architectRequests";
import ReplaceFragmentDelete from "./Modals/ReplaceFragment";
import {toast} from "react-toastify";

interface SelectedFragmentsComponentProps {
    allFragments?: AllFragments[];
    setArhitectProject: (project: ArchitectProject) => void;
    setAllFragments: (allFragments: (prevFragments: any) => any) => void;
    setSelecting: (boolean: boolean) => void;
    setCurrentIndex: (index: number) => void;
    arhitectProject: ArchitectProject | null;
    isUpdatedFragment: { [key: number]: boolean };
    setIsUpdatedFragment: React.Dispatch<React.SetStateAction<{ [key: number]: boolean }>>;
    currentIndex:number;
}

const SelectedFragmentsComponent: React.FC<SelectedFragmentsComponentProps> = ({
                                                                                   arhitectProject,
                                                                                   allFragments,
                                                                                   setArhitectProject,
                                                                                   setAllFragments,
                                                                                   setSelecting,
                                                                                   setCurrentIndex,
                                                                                   setIsUpdatedFragment,
                                                                                   isUpdatedFragment,
                                                                                   currentIndex
                                                                               }) => {
    const [openFragments, setOpenFragments] = useState<{ [key: number]: boolean }>({});
    const contentRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    const [fragment, setFragment] = useState<AllFragments | null>(null);
    const toggleFragment = (index: number) => {
        setOpenFragments((prev) => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    useEffect(() => {
        Object.keys(contentRefs.current).forEach((key) => {
            const index = Number(key);
            const content = contentRefs.current[index];

            if (content) {
                if (openFragments[index]) {
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.style.maxHeight = "0px";
                }
            }
        });
    }, [openFragments]);

    const handleDeleteFragmentOpen = (fragment: AllFragments) => {
        setFragment(fragment)
        setIsDeleteOpen(true);
    }

    const handleRemoveFragment = async () => {
        if (!fragment || !arhitectProject) return;
        const body = fragment.custom
            ? { customFragmentId: fragment.inProjectFragmentId }
            : { id: fragment.id, revit_object_id: fragment.revit_object_id };

        try {
            const response = fragment.custom
                ? await removeCustomFragment(arhitectProject.id, body)
                : await removeExistFragment(arhitectProject.id, body);

            if (response) {
                setAllFragments((prevFragments) =>
                    prevFragments.filter((allFragment: { currentIndex: number; }) => allFragment.currentIndex !== fragment.currentIndex)
                );
                setArhitectProject(response);

                setSelecting(true);
                setIsDeleteOpen(false);
            }
        } catch (error) {
            toast.error('Error when deleting the Fragment. Try again.');
            console.error("Error when deleting a fragment:", error);
        }
    };

    const updateFragment = (fragment: AllFragments) => {
        if (!localStorage.getItem('SavedIndex')) {
            localStorage.setItem('SavedIndex', String(currentIndex));
        }
        let newIndex: number | null = null;
        setIsUpdatedFragment((prev) => {
            if (prev[fragment.currentIndex]) {
                newIndex = Number(localStorage.getItem('SavedIndex')) || 0;
                localStorage.removeItem('SavedIndex');
                return {};
            }
            return { [fragment.currentIndex]: true };
        });

        if (newIndex !== null) {
            setCurrentIndex(newIndex);
        } else {
            setCurrentIndex(fragment.currentIndex);
        }
    };

    const replaceFragment = () => {
        if (!fragment) return null;
        updateFragment(fragment)
        setIsDeleteOpen(false)
    }

    return (
        <>
            {allFragments?.map((fragment) => (
                <div key={fragment.currentIndex} className="arhitect-essence-block">
                    <div
                        className={`essence-item-type ${isUpdatedFragment[fragment.currentIndex] ? "updated-fragment" : ""}`}
                    >
                        <div className="essence-item-type-info">
                            <img src="/img_vendor/item.png" className="essence-item-type-image"/>
                            <div className="essence-item-type-info-block">
                                <p className="essence-item-type-qty">Name</p>
                                <p className="essence-item-type-title">{fragment.name}</p>
                            </div>
                        </div>
                        <div className="essence-item-type-action-block">
                            <div
                                title='Update Fragment'
                                onClick={()=>updateFragment(fragment)}
                            ><IconSettingArhitect/></div>
                            <div
                                onClick={() => handleDeleteFragmentOpen(fragment)}>
                                <IconRemoveBacketArhitect/>
                            </div>
                            <div onClick={() => toggleFragment(fragment.currentIndex)} style={{cursor: "pointer"}}>
                                {openFragments[fragment.currentIndex] ? <IconArrowUpArhitect/> :
                                    <IconArrowDownArhitect/>}
                            </div>
                        </div>
                    </div>
                    <div
                        className={`essence-item-content ${openFragments[fragment.currentIndex] ? "open" : ""}`}
                        ref={(el) => (contentRefs.current[fragment.currentIndex] = el)}
                    >
                        {fragment.itemTypes.map((itemType, index) => (
                            <div className="essence-item-block" key={index}>
                                <div className="essence-item-info">
                                    <div className="essense-item-info-image">
                                        <img
                                            className="selected-item-image"
                                            src={
                                                !itemType.vendor
                                                    ? "/img_vendor/item.png"
                                                    : itemType.vendor === "cashbuild"
                                                        ? itemType.merchant_info?.image ||
                                                        (itemType.galleries?.length > 0 &&
                                                            itemType.galleries[0].medias?.length > 0 &&
                                                            itemType.galleries[0].medias[itemType.galleries[0].medias.length - 1]?.url) ||
                                                        "/img_vendor/item.png"
                                                        : (itemType.galleries?.length > 0 &&
                                                            itemType.galleries[0].medias?.length > 0 &&
                                                            itemType.galleries[0].medias[itemType.galleries[0].medias.length - 1]?.url) ||
                                                        "/img_vendor/item.png"
                                            }
                                            alt="Item"
                                        />
                                        <div className="essence-item-info-block">
                                            <p className="essence-item-type-qty">Name</p>
                                            <p className="selected-item-type-title">{itemType.title}</p>
                                        </div>
                                    </div>
                                    <div className="essence-item-info-block">
                                        <p className="essence-item-type-qty">Unit</p>
                                        <p className="selected-item-type-title">{itemType.unit ? itemType.unit : "psc"}</p>
                                    </div>

                                    <div className="essence-item-info-block">
                                        <p className="essence-item-type-qty">QTY to order</p>
                                        <input
                                            className="revit-item-type-input"
                                            type="number"
                                            value={itemType.qty}
                                            disabled={true}
                                        />
                                        <span
                                            className='revit-packing-value-steps-fragments'>psc</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            {isDeleteOpen && (
                <ReplaceFragmentDelete
                    isOpen={true}
                    onRequestClose={() => setIsDeleteOpen(false)}
                    onConfirm={handleRemoveFragment}
                    onCancel={replaceFragment}
                    message={
                        <>
                            Are you sure you want to delete the Fragment:{" "}
                            <span className="revit-remove-fragment-name">{fragment?.name}</span>?
                            You can replace or update the Fragment for a revit object.
                        </>
                    }
                    confirmText="Delete"
                    cancelText="Replace"
                />

            )}
        </>
    );
};

export default SelectedFragmentsComponent;
