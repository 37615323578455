import {
    addRevitCustomFragmentToProject,
    addRevitExistFragmentToProject,
    createProjectArhitect,
    deleteRevitCustomFragmentToProject,
    deleteRevitExistFragmentToProject,
    fetchAllSkipsRevitObject,
    fetchProjectByUrn,
    fetchProjectByUrnRevitFragment,
    fetchProjectCreatedByUrn,
    get,
    revitSkipFragment,
    updateArchitectProject
} from "../../api/APIWraper";
import {PostFormData} from "../../api/ApiWrapperImage";
import {toast} from "react-toastify";

const BASE_URL_ARCHITECTOR = process.env.REACT_APP_BASE_URL_ARCHITECTOR;

export const getProjectByUrn = async (urn: string) => {
    const response = await fetchProjectByUrn(urn);
    if (response.status === 200) {
        return response.data.id;
    }
    toast.error('Failed to get the project by this URN');
};

export const getCreatedProjectByUrn = async (urn: string) => {
    return  await fetchProjectCreatedByUrn(urn);

};

export const getRevitFragment = async (externalId: string, projectId: number) => {
    const response = await fetchProjectByUrnRevitFragment(encodeURIComponent(externalId), projectId);
    if (response.status === 200) {
        return response.data;
    }
    toast.error('Failed to fetch Revit fragment');
};

export const skipRevitFragment = async (id: number, name: string | null) => {
    const response = await revitSkipFragment(id);
    if (response.status === 200) {
        return `You skipped the Fragment${name ? `: ${name}` : ''}.`;
    }
    toast.error('Could not skip the fragment');
};

export const uploadProjectImage = async (projectId: number, imageFile: File) => {
    const formData = new FormData();
    formData.append("file", imageFile);
    formData.append("projectId", projectId.toString());

    const response = await PostFormData(BASE_URL_ARCHITECTOR || "", `/architect_project/${projectId}/media`, formData);

    if (response.status === 200 || response.status === 201) {
        return response.data;
    }
    toast.error('Failed to upload image');
};

export const createArchitectProject = async (body: any, images: File[] | null) => {
    const response = await createProjectArhitect(body);
    if (response.status === 201 && images) {
        const uploadedImages = await Promise.all(
            images.map(image => uploadProjectImage(response.data.id, image))
        );
        return { project: response.data, images: uploadedImages };
    }
    return { project: response.data, images: [] };
};


export const fetchItemTypes = async (searchTerm:string,page:string,sortDir:string) =>{
    try {
        let url = '';
        url = searchTerm
            ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types?page=${page}&perPage=5&title=${encodeURIComponent(searchTerm)}&sortOrder=${sortDir}`
            : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types?page=${page}&perPage=5&sortOrder=${sortDir}`;
         return await get(url);

    } catch (error){
        console.log(error)
    }
}
export const getAllSkipsRevitObject = async (projectId: number) => {
    const response = await fetchAllSkipsRevitObject(projectId);
    if (response.status === 200) {
        return response.data.data;
    }
    toast.error('Failed to fetch skips Revit objects');
};



export const addExistFragment = async (projectId: number,body:{}) => {
    try {
        const response = await addRevitExistFragmentToProject(projectId,body)
        if (response.status === 200) {
            return response.data
        } else {
            toast.error('Failed to add exist Fragment');
        }

    } catch (error){
        toast.error('Failed to add exist Fragment');
    }
}

export const addCustomFragment = async (projectId: number,body:{}) => {
    try {
        const response = await addRevitCustomFragmentToProject(projectId,body)
        if (response.status === 200) {
            return response.data
        } else {
            toast.error('Failed to add exist Fragment');
        }
    } catch (error){
        toast.error('Failed to add exist Fragment');
    }
}


export const removeExistFragment = async (projectId: number, body: {}) => {
    try {
        const response = await deleteRevitExistFragmentToProject(projectId,body)
        if (response.status === 200) {
            return response.data
        } else {
            toast.error('Failed to remove exist Fragment');
            return null
        }
    } catch (error){
        toast.error('Failed to remove exist Fragment');
    }
}

export const removeCustomFragment = async (projectId: number, body: {}) => {
    try {
        const response = await deleteRevitCustomFragmentToProject(projectId,body)
        if (response.status === 200) {
            return response.data
        } else {
            toast.error('Failed to remove custom Fragment');
            return null
        }
    } catch (error){
        toast.error('Failed to remove custom Fragment');
    }
}


export const updateProject = async (projectId:number, body: any, images: File[] | null) => {
    const response = await updateArchitectProject(projectId,body);
    if (response.status === 200 && images) {
        const uploadedImages = await Promise.all(
            images.map(image => uploadProjectImage(response.data.id, image))
        );
        return { project: response.data, images: uploadedImages };
    }
    return { project: response.data, images: [] };
};