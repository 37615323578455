// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arhitector-upload-progress-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.arhitector-upload-progress-bar-container {
    width: 300px;
    background-color: #d9d9d9;
    overflow: hidden;
    border-radius: 10px;
}

.arhitector-upload-container-progressBarFill {
    width: 100%;
    height: 15px;
    background-color: var(--basic-btn-color);
    border-radius: 10px;

}`, "",{"version":3,"sources":["webpack://./src/Page/ArchitectPage/ArchitectComponents/ArhitectorAllProject/ProgressBarLoading.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;AACf;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,wCAAwC;IACxC,mBAAmB;;AAEvB","sourcesContent":[".arhitector-upload-progress-bar{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n}\n.arhitector-upload-progress-bar-container {\n    width: 300px;\n    background-color: #d9d9d9;\n    overflow: hidden;\n    border-radius: 10px;\n}\n\n.arhitector-upload-container-progressBarFill {\n    width: 100%;\n    height: 15px;\n    background-color: var(--basic-btn-color);\n    border-radius: 10px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
