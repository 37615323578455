import './style.css';
import React, {useCallback, useState, useEffect} from 'react';
import {clearLocalStorageExceptToken} from "../../utils/ClearLocalStorage";
import {clearTemplates} from "../../Slice/LocalTemplate/LocalTemplateSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import {IconCheckTrue, IconLogOut} from "../../IconComponents/IconComponents";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {RootState} from "../../store";
import Step3SocketModal from "../Modals/Step3ModalSocket/Step3SocketModal";

interface WebSocketMessage {
    data: {
        item: TItem;
        message: string;
    }[];
}

interface TItem {
    id: string;
    title: string;
    price: number;
    vendor: string;
    merchant_info?: {
        image?: string;
    };
    galleries?: {
        medias: { url: string }[];
    }[];
}

function TopHeader() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [isSpinning, setIsSpinning] = useState(false);
    const [activeButton, setActiveButton] = useState('');
    const [isHovered, setIsHovered] = useState('');
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const [isSmallScreenMedium, setIsSmallScreenMedium] = useState<boolean>(window.innerWidth <= 1060);

    const handleResize = () => {
        setIsSmallScreenMedium(window.innerWidth <= 1060);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const isOnAuthPage = location.pathname.startsWith('/auth');

    const buttons = ['Home', 'About Us', 'Functionality', 'Pricing', 'Support'];
    const buttonPaths: Record<string, string> = {
        'Home': '/home',
        'About Us': '/aboutUs',
        'Functionality': '/functionality',
        'Pricing': '/pricing',
        'Support': '/support'
    };

    useEffect(() => {
        const currentPath = location.pathname;
        const activeBtn = Object.keys(buttonPaths).find(key => buttonPaths[key] === currentPath);
        if (activeBtn) {
            setActiveButton(activeBtn);
        } else {
            setActiveButton('');
        }
    }, [location.pathname, buttonPaths]);

    const navigateToPage = useCallback((path: string) => {
        clearLocalStorageExceptToken();
        dispatch(clearTemplates());
        navigate(path);
    }, [dispatch, navigate]);

    const handleButtonClick = useCallback((button: string) => {
        setActiveButton(button);
        const path = buttonPaths[button];
        if (path) {
            navigateToPage(path);
        }
    }, [navigateToPage, buttonPaths]);

    const handleGetStarted = () => {
        if (isAuthenticated) {
            navigate('/assistance');
        } else {
            navigate('/auth/login');
        }
    };

    const handleLogoClock = () => {
        if (isAuthenticated) {
            navigate('/assistance');
        } else {
            navigate('/auth/login');
        }
    };

    const handleLogOut = () => {
        toast.info('Wait, we\'re coming out...');
        setIsSpinning(true);

        setTimeout(() => {
            sessionStorage.removeItem('Bearer');
            localStorage.removeItem('Bearer');
            localStorage.removeItem('role');
            localStorage.removeItem('oldRole')
            localStorage.removeItem('userId')
            localStorage.removeItem('Iam')
            localStorage.removeItem('autodeskToken')
            navigate('/home');
            setIsSpinning(false);
            toast.success('You have successfully logged out!');
        }, 1000);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const [progress, setProgress] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [itemQuantity, setItemQuantity] = useState<number>(0);
    const [, setLoadedItems] = useState<number>(0);

    useEffect(() => {
        const ITEM_STORAGE_KEY = 'SocketItemModal';
        const QUANTITY_STORAGE_KEY = 'ItemQuantity';

        const updateProgress = () => {
            const itemQuantityStr = localStorage.getItem(QUANTITY_STORAGE_KEY);
            const socketItemsStr = localStorage.getItem(ITEM_STORAGE_KEY);

            if (!itemQuantityStr) {
                setProgress(0);
                setIsLoading(false);
                setItemQuantity(0);
                setLoadedItems(0);
                return;
            }

            const itemQuantityNum = parseInt(itemQuantityStr, 10);
            if (isNaN(itemQuantityNum) || itemQuantityNum === 0) {
                setProgress(0);
                setIsLoading(false);
                setItemQuantity(0);
                setLoadedItems(0);
                return;
            }

            if (!isLoading) {
                setIsLoading(true);
                setItemQuantity(itemQuantityNum);
            }

            let currentCount = 0;
            if (socketItemsStr) {
                try {
                    const messages = JSON.parse(socketItemsStr) as WebSocketMessage[];
                    currentCount = messages.reduce((acc, msg) => acc + msg.data.length, 0);
                } catch (err) {
                    console.error("Error when parsing SocketItemModal from localStorage:", err);
                    currentCount = 0;
                }
            }

            setLoadedItems(currentCount);
            const newProgress = Math.min((currentCount / itemQuantityNum) * 100, 100);
            setProgress(newProgress);

            if (newProgress >= 100) {
                setIsLoading(false);
            }
        };

        updateProgress();

        const interval = setInterval(updateProgress, 1000);

        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === QUANTITY_STORAGE_KEY || event.key === ITEM_STORAGE_KEY) {
                updateProgress();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            clearInterval(interval);
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [isLoading]);

    const handleLoaderClick = () => {
        setModalOpen(true);
    };


    const renderLoaderSocketModal = () => {
        return (
            isAuthenticated && itemQuantity > 0 && (
                <div
                    className="header-loader-container"
                    onClick={handleLoaderClick}
                    title={progress >= 100 ? "Open modal" : "Product receipt"}
                >
                    {isLoading ? (
                        <>
                            <div className="loader-socket-modal"></div>
                            <div className="loader-text-socket-modal">Loading {itemQuantity} products</div>
                        </>
                    ) : (
                        <div className="loader-complete">
                            <IconCheckTrue/>
                            <span className='loader-text-socket-modal'>{itemQuantity} products loaded</span>
                        </div>
                    )}
                    <div className="progress-bar-socket-modal">
                        <div
                            className="progress-bar-fill-socket-modal"
                            style={{width: `${progress}%`}}
                        ></div>
                    </div>
                </div>
            )
        )
    }

    return (
        <div
            className={!isSmallScreenMedium ? "top-header" : "top-header-mobile"}
        >

            <div className='top-header-red'>

                <div className='top-header-left-container'>
                    <button onClick={handleLogOut} className='top-header-btn-logout'>
                        {isAuthenticated && !isOnAuthPage && (
                            <div title='Log out' className={`icon-container ${isSpinning ? 'spin-logout' : ''}`}>
                                <IconLogOut/>
                            </div>
                        )}
                    </button>
                    <div onClick={handleLogoClock} className='header-logo'>
                        <img className='header-logo-img' src='/img_projects/gobuild.svg' alt='Logo'/>
                    </div>
                </div>
                <div className="header-switcher">
                    {buttons.map(button => (
                        <button
                            key={button}
                            className={`header-switcher-btn ${activeButton === button ? 'active-header-btn' : ''} ${isHovered === button ? 'hovered-header-btn' : ''}`}
                            onClick={() => handleButtonClick(button)}
                            onMouseEnter={() => setIsHovered(button)}
                            onMouseLeave={() => setIsHovered('')}
                        >
                            {button}
                        </button>
                    ))}
                </div>

                <div className='top-header-right-container'>
                    <button onClick={handleGetStarted} className='top-header-btn-calc font-reg'>
                        <p>Get Started</p>
                    </button>
                </div>

                {!isSmallScreenMedium && (
                    renderLoaderSocketModal()
                )}
            </div>
            <Step3SocketModal
                isOpen={isModalOpen}
                onRequestClose={handleModalClose}
            />
            {isSmallScreenMedium && (
                <div className='top-header-red-mobile'>

                    {renderLoaderSocketModal()}
                </div>
            )}
        </div>
    );
}

export default TopHeader;
