import React, {useEffect, useState} from 'react';
import {
    IconArrowDownArhitectQuestion,
} from "../../../IconComponents/IconComponents";
import {Fragment, FragmentData} from "../../../types/TemplateType";
import '../ArhitectStyles/Managements.css';
import PaginationAssistance from "../../../components/PaginationAssistance/pagination";
import {AllFragments} from "../ArсhitectPage";

interface IQuestionAnswersProps {
    fragment: Fragment;
    setFinalData: (arg0: FragmentData[]) => void;
    isSelected: boolean;
    currentIndex: number
    allFragments: AllFragments[]
}

const QuestionAnswers: React.FC<IQuestionAnswersProps> = ({
                                                              fragment,
                                                              setFinalData,
                                                              isSelected,
                                                              currentIndex,
                                                              allFragments
                                                          }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedAnswers, setSelectedAnswers] = useState<Record<string, string>>({});
    const questions = Object.values(fragment.data || {})
        .filter((fragmentData: any) => typeof fragmentData === "object" && fragmentData.question)
        .map((fragmentData: any) => ({[fragmentData.key]: fragmentData}));

    const questionsPerPage = 4;
    const totalQuestions = questions.length;

    const toggleExpanded = () => {
        setIsExpanded((prevState) => !prevState);
    };
    const indexOfLastQuestion = currentPage * questionsPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    const currentQuestions = questions.slice(indexOfFirstQuestion, indexOfLastQuestion);

    useEffect(() => {
        const currentFragment = allFragments.find((fragment) => fragment.currentIndex === currentIndex);
        if (currentFragment) {
            const checkFragment = currentFragment.id === fragment.id
            setIsExpanded(checkFragment)

        }

    }, [fragment,currentIndex])

    useEffect(() => {
        const existingFragment = allFragments.find(
            (fragment) => fragment.currentIndex === currentIndex && !fragment.custom
        );

        if (existingFragment && existingFragment.data) {
            const restoredAnswers = existingFragment.data.reduce(
                (acc, item) => {
                    acc[item.key] = item.value;
                    return acc;
                },
                {} as Record<string, string>
            );
            setSelectedAnswers(restoredAnswers);
        } else {
            const defaultAnswers = Object.values(fragment.data || {}).reduce(
                (acc, fragmentData: any) => {
                    if (fragmentData.key && fragmentData.value) {
                        acc[fragmentData.key] = fragmentData.value;
                    }
                    return acc;
                },
                {} as Record<string, string>
            );
            setSelectedAnswers(defaultAnswers);
        }
    }, [currentIndex, allFragments,fragment]);

    const handlePaginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    const handleAnswerChange = (key: string, value: string) => {
        setSelectedAnswers((prevState) => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleInputChange = (key: string, value: string) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setSelectedAnswers((prevState) => ({
                ...prevState,
                [key]: value
            }));
        }
    };

    const buildFinalData = (): FragmentData[] => {
        return Object.entries(selectedAnswers).map(([key, value]) => ({
            key: fragment.data?.[key]?.key || key,
            value,
            question: fragment.data?.[key]?.question || "",
            select: fragment.data?.[key]?.select || "",
            answers: fragment.data?.[key]?.answers || [],
            item_types: fragment.data?.item_types || []
        }));
    };

    useEffect(() => {
        if (isSelected) {
            const finalData = buildFinalData();
            setFinalData(finalData);
        }
    }, [selectedAnswers, isSelected, currentIndex]);

    return (
        <div className={`revit-calc-block ${isExpanded ? 'expanded' : 'collapsed'}`}>
            <h3 className="revit-calc-title" onClick={toggleExpanded}>
                <p>Extracted project data:</p>
                <p className='revit-fragment-title-text'>{fragment.title || 'Fragment'}</p>
                <button className={`revit-toggle-button ${isExpanded ? 'expanded' : 'collapsed'}`}>
                    <IconArrowDownArhitectQuestion/>
                </button>
            </h3>
            <div className={`revit-calc-block-content ${isExpanded ? 'expanded' : 'collapsed'}`}>
                <div className='revit-calc-content'>
                    {currentQuestions.map((fragmentData: any, index: number) => {
                        const key = Object.keys(fragmentData)[0];
                        const data = fragmentData[key];

                        return (
                            <div key={key} className="revit-answer-block">
                                <div className="revit-question-block">{data.question}</div>
                                <div className="revit-calc-field-fragment">
                                    {data.select === 'select' && data.answers ? (
                                        data.answers.map((answer: any, idx: number) => (
                                            <label key={idx} className="step-answer-revit">
                                                <input
                                                    type="radio"
                                                    name={key}
                                                    value={answer.value}
                                                    checked={selectedAnswers[key] === answer.value}
                                                    onChange={() => handleAnswerChange(key, answer.value)}
                                                />
                                                <span className="radio-custom"></span>
                                                {answer.title}
                                            </label>
                                        ))
                                    ) : (
                                        <input
                                            type="text"
                                            value={selectedAnswers[key] ?? ""}
                                            onChange={(e) => handleInputChange(key, e.target.value)}
                                            onBlur={(e) => handleAnswerChange(key, e.target.value)}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}

                </div>
                {totalQuestions > questionsPerPage && (
                    <PaginationAssistance
                        projectsPerPage={questionsPerPage}
                        totalProjects={totalQuestions}
                        paginate={handlePaginate}
                        currentPage={currentPage}
                    />
                )}
            </div>
        </div>
    );
};

export default QuestionAnswers;
