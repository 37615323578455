import {Project} from "../types/ProjectTypes";
import {ItemType, Template} from "../types/TemplateType";
import ILaborItemType from "../Interfaces/ProjectInterfaces/IlaborItemType";
import IToolsItemType from "../Interfaces/ProjectInterfaces/IToolsItemType";

export const filterLaborAndToolsItemTypes = (
    data: Project | Template,
    templateIndex: number,
    filterType?: 'labor' | 'tool'
): { laborItemTypes?: ILaborItemType[], toolsItemTypes?: IToolsItemType[] } => {
    const newLaborItemTypes: ILaborItemType[] = [];
    const newToolsItemTypes: IToolsItemType[] = [];
    const templates = 'data' in data && data.data.templates ? data.data.templates : [data as Template];
    if (templates[0]) {
        const currentTemplate = templates[0];

        currentTemplate.data.fragments.forEach(
            (fragment: { data: { item_types: ItemType[] }; id: number }, fragmentIndex: number) => {
                fragment.data.item_types.forEach((itemType, itemTypeIndex) => {
                    if (itemType.labor && (!filterType || filterType === 'labor')) {
                        newLaborItemTypes.push({
                            item_type: itemType,
                            item: itemType.item ? itemType.item : null,
                            item_type_id: itemType.id,
                            item_id: itemType.item ? itemType.item.id : null,
                            template_index: templateIndex,
                            fragment_index: fragmentIndex,
                            item_type_index: itemTypeIndex,
                            template_id: currentTemplate.id,
                            fragment_id: fragment.id,
                            qty: itemType.qty
                        });
                    } else if (itemType.tool && (!filterType || filterType === 'tool')) {
                        newToolsItemTypes.push({
                            unit: "",
                            clone_id: 0,
                            item_type: itemType,
                            item: itemType.item ? itemType.item : null,
                            item_type_id: itemType.id,
                            item_id: itemType.item ? itemType.item.id : null,
                            template_index: templateIndex,
                            fragment_index: fragmentIndex,
                            item_type_index: itemTypeIndex,
                            template_id: currentTemplate.id,
                            fragment_id: fragment.id,
                            qty: itemType.qty
                        });
                    }
                });
            }
        );
    }
    if (filterType === 'labor') {
        return { laborItemTypes: newLaborItemTypes };
    } else if (filterType === 'tool') {
        return { toolsItemTypes: newToolsItemTypes };
    }
    return { laborItemTypes: newLaborItemTypes, toolsItemTypes: newToolsItemTypes };
};
